// core
import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
//hooks
import { useMutationCreateTicket } from '../../../../hooks/api';
// utils
import { convertToFormData } from '../../../../utils/commons';
// locales
import { useTranslation } from 'react-i18next';
// types
import { EButtonSizeType, EButtonTypeType } from 'types/units';
import type { UploadProps } from 'antd';

// components(atoms)
import { TicketsCreateSEO } from './SEO';
import { Button, Form, Input, Upload, message } from 'antd';
import { ArrowLeftIcon, PaperClipIcon } from 'components/icons';

// styles
import './index.scss';
import { defaultValues } from './schema';
import { toast } from 'react-toastify';
import * as yup from 'yup';

//data
const { TextArea } = Input;

const SupportTicketsCreate = () => {
  const { t } = useTranslation();
  const createTicketSchema = yup.object().shape({
    subject: yup
      .string()
      .required(t('validations.required'))
      .min(5, t('validations.minLength') + ' 5')
      .max(100, t('validations.maxLength') + ' 100'),
    email: yup
      .string()
      .required(t('validations.required'))
      .max(70, t('validations.maxLength') + ' 70')
      .email(t('validations.email')),
    content: yup
      .string()
      .required(t('validations.required'))
      .min(15, t('validations.minLength') + ' 15')
      .max(500, t('validations.maxLength') + ' 500'),
    file: yup.mixed().test('fileSize', t('validations.fileSize'), fileList => {
      if (!fileList) return true;
      if (!fileList.fileList.length) return true;
      return fileList.fileList[0].size <= 2000000;
    }),
  });

  const yupSync = {
    async validator({ field }, value) {
      await createTicketSchema.validateSyncAt(field, { [field]: value });
    },
  };
  const handleFileSelection = event => {
    if (event.type !== 'image/png' && event.type !== 'image/jpeg') {
      form.resetFields();
      form.setFieldValue('file', null);
      toast.error('File must be an image!');
    }
    event.stopPropagation();
  };
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const {
    data: createTicketResponse,
    isLoading: isLoadingCreateTicket,
    mutate: createTicketMutate,
  } = useMutationCreateTicket();

  const transformFile = fileObj => {
    return new File([fileObj], fileObj.name, {
      type: fileObj.type,
      lastModified: fileObj.lastModified,
    });
  };
  const onSubmitForm = async payload => {
    const { file, ...clearedFields } = payload;
    payload = {
      ...clearedFields,
      ...(file &&
      file.file?.status !== 'removed' &&
      file.fileList?.length &&
      (file.file?.type === 'image/png' || file.file?.type === 'image/jpeg')
        ? { file: file.fileList[0].originFileObj }
        : {}),
    };
    const formData = convertToFormData(payload);
    createTicketMutate({ formData });
  };

  useEffect(() => {
    if (!isLoadingCreateTicket && createTicketResponse) {
      toast.success(t('toastTickedCreated'));
      form.resetFields();
      navigate({ pathname: `/support/ticket/${createTicketResponse.id}` });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createTicketResponse, isLoadingCreateTicket]);

  return (
    <>
      <TicketsCreateSEO />
      <div className="tickets-create">
        <div className="app-container app-container--small">
          <div className="tickets-create__wrap">
            <div className="tickets-create__head">
              <div className="tickets-create__head--back">
                <RouterLink to="/support/tickets">
                  <Button
                    type={EButtonTypeType.Default}
                    className={'ant-btn-ghost--grey'}
                    ghost
                    size={EButtonSizeType.Middle}
                  >
                    <ArrowLeftIcon />
                    {t('back')}
                  </Button>
                </RouterLink>
              </div>
              <div className="tickets-create__head--title">{t('newChat')}</div>
              <div className="tickets-create__head--action">
                <Button
                  type={EButtonTypeType.Primary}
                  size={EButtonSizeType.Middle}
                  ghost
                >
                  {t('goToJivo')}
                </Button>
              </div>
            </div>
            <div className="tickets-create__form">
              <div className="tickets-create__form--title">
                {t('describeProblem')}
              </div>
              <div className="tickets-create__form--desc">
                {t('teamWillConnect')}
              </div>
              <Form
                onFinish={onSubmitForm}
                initialValues={defaultValues}
                form={form}
                layout="vertical"
              >
                <Form.Item
                  name={'subject'}
                  /*@ts-ignore*/
                  rules={[yupSync]}
                  label={t('messageTheme')}
                >
                  <Input
                    className="ant-input--borderless"
                    placeholder={t('messageTheme')}
                  />
                </Form.Item>
                <Form.Item
                  name={'email'}
                  /*@ts-ignore*/
                  rules={[yupSync]}
                  label={t('mailForResponse')}
                >
                  <Input
                    className="ant-input--borderless"
                    placeholder={t('yourMail')}
                  />
                </Form.Item>
                <Form.Item
                  name={'content'}
                  /*@ts-ignore*/
                  rules={[yupSync]}
                  label={t('containing')}
                >
                  <TextArea
                    placeholder={t('enterContaining')}
                    className="ant-input--borderless"
                    autoSize={{ minRows: 3, maxRows: 3 }}
                  />
                </Form.Item>
                <div className="tickets-create__form--actions">
                  <Form.Item
                    name={'file'}
                    /*@ts-ignore*/
                    rules={[yupSync]}
                  >
                    <Upload
                      listType="picture"
                      accept="image/png, image/jpeg"
                      maxCount={1}
                      beforeUpload={e => handleFileSelection(e)}
                    >
                      <Button
                        className={'ant-btn-ghost--grey'}
                        ghost
                        type={EButtonTypeType.Default}
                        size={EButtonSizeType.Large}
                        icon={<PaperClipIcon />}
                      />
                    </Upload>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      disabled={isLoadingCreateTicket}
                      htmlType="submit"
                      type={EButtonTypeType.Primary}
                      size={EButtonSizeType.Large}
                    >
                      {t('sendTicket')}
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { SupportTicketsCreate };
