import React, { useEffect, useState } from 'react';
import { Checkbox, Typography, Button, Tag } from 'antd';
import { NavLink, useNavigate } from 'react-router-dom';
// utils

//locales
import { useTranslation } from 'react-i18next';

// store
import { useAppStore } from 'store';

//hooks

import { useQueryGetAuthLink } from '../../../../hooks/api';

// types
import { EButtonSizeType, EButtonTypeType } from 'types/units/button';

// components
import {
  SteamIcon,
  CartOutlineIcon,
  LightningIcon,
  ReloadIcon,
} from 'components/icons';

// styles
import './index.scss';

// data
/*@ts-ignore*/
import MaskotLogin from 'assets/img/maskotLogin.png';

const SteamAuthModal = ({ modalId }) => {
  const { t } = useTranslation();
  const [userTerms, setUserTerms] = useState(false);
  const [enabledFetchAuth, setEnabledFetchAuth] = useState(false);

  const { data: authLinkResponse, isLoading: isLoadingAuthLink } =
    useQueryGetAuthLink(enabledFetchAuth);

  useEffect(() => {
    if (!isLoadingAuthLink && authLinkResponse) {
      window.location.href = `${authLinkResponse.authRedirectUrl}`;
    }
  }, [authLinkResponse, isLoadingAuthLink]);
  const onAuth = () => {
    // sModalClose(modalId);
    setEnabledFetchAuth(true);
  };

  const onChangeUserTerms = ({ target: { checked } }) => {
    setUserTerms(checked);
  };

  return (
    <div className="steam-auth-modal">
      <div className="steam-auth-modal__head">
        <div className="steam-auth-modal__head-bg" />
        <img
          src={MaskotLogin}
          alt="SkinstyMaskot"
          className="steam-auth-modal__head-maskot"
        />
      </div>
      <div className="steam-auth-modal__body">
        <div className="steam-auth-modal__title">{t('authViaSteam')}</div>
        <Typography className="steam-auth-modal__text">
          {t('acceptToAuth')}
        </Typography>
        <div className="steam-auth-modal__tags">
          <div className="steam-auth-modal__scroll">
            <div className="steam-auth-modal__grid">
              <Tag icon={<CartOutlineIcon />} bordered={false}>
                {t('buy')}
              </Tag>
              <Tag icon={<LightningIcon />} bordered={false}>
                {t('sell')}
              </Tag>
              <Tag icon={<ReloadIcon />} bordered={false}>
                {t('trade')}
              </Tag>
            </div>
          </div>
        </div>
      </div>
      <div className="steam-auth-modal__actions">
        <Checkbox
          checked={userTerms}
          onChange={onChangeUserTerms}
          className="steam-auth-modal__checkbox"
        >
          {t('iAgreeWith')}{' '}
          <NavLink to={'/docs/terms'} target={'_blank'}>
            {t('userAgreement')}
          </NavLink>{' '}
          {t('andAcceptConditions')}
        </Checkbox>
        <Button
          className="steam-auth-modal__btn-auth"
          block
          disabled={!userTerms || !isLoadingAuthLink}
          type={EButtonTypeType.Primary}
          size={EButtonSizeType.Large}
          onClick={() => {
            onAuth();
          }}
        >
          <SteamIcon />
          {t('login')}
        </Button>
      </div>
    </div>
  );
};

export { SteamAuthModal };
