import React from 'react';
import { Button } from 'antd';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import { MEDIA } from 'utils/constants';
// locale
import { useTranslation } from 'react-i18next';
// store

// types
import { EButtonSizeType } from 'types/units';

// components
import { ArrowLeftIcon } from 'components/icons';

// styles
import './index.scss';
import { gameTypeSlug } from '../../../../utils/commons';

//data

const ProductAside = ({ productSlug }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { hash } = useLocation();
  const { width } = useWindowSize();

  const onBackButton = () => {
    navigate('/market');
  };

  return (
    <div className="product-aside">
      <Button
        size={EButtonSizeType.Middle}
        ghost
        className={'ant-btn-ghost--grey'}
        onClick={() => {
          onBackButton();
        }}
      >
        <ArrowLeftIcon />
        {t('back')}
      </Button>
      {width > MEDIA.M1024 && (
        <div className="product-aside__nav">
          <NavLink
            to={`/market/${gameTypeSlug(productSlug.provider)}/${
              productSlug.seo?.sku ? productSlug.seo?.sku : productSlug.id
            }?id=${productSlug.id}#info`}
            className={
              'product-aside__link' +
              (hash === '#info' ? ' product-aside__link--active' : '')
            }
          >
            {t('generalAndInspection')}
          </NavLink>
          <NavLink
            to={`/market/${gameTypeSlug(productSlug.provider)}/${
              productSlug.seo?.sku ? productSlug.seo?.sku : productSlug.id
            }?id=${productSlug.id}#purchase_requests`}
            className={
              'product-aside__link' +
              (hash === '#purchase_requests'
                ? ' product-aside__link--active'
                : '')
            }
          >
            {t('purchaseRequests')}
          </NavLink>
          <NavLink
            to={`/market/${gameTypeSlug(productSlug.provider)}/${
              productSlug.seo?.sku ? productSlug.seo?.sku : productSlug.id
            }?id=${productSlug.id}#similar_products`}
            className={
              'product-aside__link' +
              (hash === '#similar_products'
                ? ' product-aside__link--active'
                : '')
            }
          >
            {t('similarItems')}
          </NavLink>
          <NavLink
            to={`/market/${gameTypeSlug(productSlug.provider)}/${
              productSlug.seo?.sku ? productSlug.seo?.sku : productSlug.id
            }?id=${productSlug.id}#statistics`}
            className={
              'product-aside__link' +
              (hash === '#statistics' ? ' product-aside__link--active' : '')
            }
          >
            {t('statistics')}
          </NavLink>
        </div>
      )}
    </div>
  );
};

export { ProductAside };
