// core
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

//hooks
import { useQueryFetchProductInfo } from '../../../hooks/api';

// utils

// locales

// store
import { useAppStore } from 'store';

// types

// components(atoms)
import { ProductSEO } from './SEO';
import { ProductAside } from './ProductAside';
import { ProductRequests } from './ProductRequests';
import { ProductSimilar } from './ProductSimilar';
import { ProductStats } from './ProductStats';
import { ProductInfo } from './ProductInfo';
import { AppLoader, ModalTeleport } from 'components/atoms';
import { OfferTradeModal } from 'components/features/';

// styles
import './index.scss';
import { ELoaderTypeType } from '../../../components/atoms/AppLoader/types';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';

// data

// func
const Product = () => {
  const navigate = useNavigate();
  const { slug: productSlug } = useParams();
  const [query, setQuery] = useQueryParams({
    id: withDefault(StringParam, undefined),
  });
  const { data: productData, isLoading: isLoadingProductData } =
    useQueryFetchProductInfo(productSlug, query.id);

  const [isLoadedProductRequests, setIsLoadedProductRequests] = useState(false);
  const [isLoadedProductSimilar, setIsLoadedProductSimilar] = useState(false);
  const [isLoadedProductStats, setIsLoadedProductStats] = useState(false);

  useEffect(() => {
    if (!isLoadingProductData && !productData) {
      navigate(`/market`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productData, isLoadingProductData]);

  useEffect(() => {
    if (
      !isLoadingProductData &&
      productData &&
      isLoadedProductRequests &&
      isLoadedProductSimilar &&
      isLoadedProductStats
    ) {
      window['prerenderReady'] = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    productData,
    isLoadingProductData,
    isLoadedProductRequests,
    isLoadedProductSimilar,
    isLoadedProductStats,
  ]);

  return (
    <>
      <ProductSEO
        title={productData ? productData?.originalMarketName : ''}
        desc={productData ? productData?.description : ''}
        image={
          productData
            ? productData.image
              ? productData.image
              : productData.preview
            : ''
        }
      />
      <div className="product">
        <div className="app-container">
          {!isLoadingProductData && productData ? (
            <>
              <div className="product__grid">
                <div className="product__aside">
                  <ProductAside productSlug={productData} />
                </div>

                <div className="product__main">
                  <ProductInfo product={productData} />

                  <ProductRequests
                    product={productData}
                    onLoaded={() => {
                      setIsLoadedProductRequests(true);
                    }}
                  />
                  <ProductSimilar
                    product={productData}
                    onLoaded={() => {
                      setIsLoadedProductSimilar(true);
                    }}
                  />
                  <ProductStats
                    marketName={productData.marketName}
                    extra={productData.exterior}
                    gameType={productData.provider}
                    onLoaded={() => {
                      setIsLoadedProductStats(true);
                    }}
                  />

                  {productData?.description?.length && (
                    <div
                      className="product__desc"
                      dangerouslySetInnerHTML={{
                        __html: productData.description,
                      }}
                    />
                  )}
                </div>
              </div>
              <ModalTeleport
                centered
                modalId={'offerTrade'}
                width={1024}
                modalClass={'offer-trade-modal__wrapper'}
              >
                <OfferTradeModal modalId={'offerTrade'} />
              </ModalTeleport>
            </>
          ) : (
            <div className="product__loader">
              <AppLoader type={ELoaderTypeType.Component} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export { Product };
