import React, { useState } from 'react';
import { Select, Space, Button } from 'antd';
import { useWindowSize } from 'react-use';
import { NavLink } from 'react-router-dom';
// locale
import { useTranslation } from 'react-i18next';
// store
import { useAppStore, useExtStore } from 'store';

// types
import { ESelectSizeType, EButtonSizeType, EButtonTypeType } from 'types/units';

// components
import { ArrowDownIcon } from 'components/icons';
import { ModalTeleport, AppAutocomplete } from 'components/atoms';
import { NewTradeModal } from 'components/features';
// styles
import './index.scss';

//data
import { sortPrice, sortQuality } from './data';
import { IPropsType } from './types';
const { Option } = Select;

const getRandomInt = (max: number, min = 0) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

const DealsHead = ({
  enabled,
  filterData,
  onChangeQuality,
  onChangePrice,
  onChangeSearch,
  createRequest,
  onCreateRequest,
  onSelectAll,
  onRemoveSelected,
  onRemoveAll,
}: IPropsType) => {
  const { t } = useTranslation();

  const { width } = useWindowSize();

  const { sModalOpen } = useAppStore();
  const { isExtOnline } = useExtStore();

  const [options, setOptions] = useState<any[]>([]);

  const onSearch = (value: string) => {
    onChangeSearch(value);
    // setOptions(value ? new Array(getRandomInt(5)).join('.').split('.') : []);
  };

  const onChangeSortPrice = (value: string) => {
    onChangePrice(value);
  };

  const onChangeSortQuality = (value: string) => {
    onChangeQuality(value);
  };

  const onCreateAutoRequest = () => {
    onCreateRequest();
  };

  const onCreateTrade = () => {
    if (!isExtOnline) {
      sModalOpen('installExtensionModal');
    } else {
      sModalOpen('newTrade');
    }
  };

  const onButtonSelectAll = () => {
    if (onSelectAll) {
      onSelectAll();
    }
  };
  const onButtonRemoveSelected = () => {
    if (onRemoveSelected) {
      onRemoveSelected();
    }
  };
  const onButtonRemoveAll = () => {
    if (onRemoveAll) {
      onRemoveAll();
    }
  };

  return (
    <div className="deals-head">
      <div className="app-container">
        <div className="deals-head__content">
          <div className="deals-head__title">{t('transactions')}</div>
          <div className="deals-head__actions">
            <Space.Compact className="ant-space-compact--nav deals-head__tabs">
              <NavLink
                to="/deals/trade"
                className={({ isActive }) =>
                  isActive ? ' ant-space-compact-nav--active' : ''
                }
              >
                {t('bargain')}
              </NavLink>
              <NavLink
                to="/deals/autobuy"
                className={({ isActive }) =>
                  isActive ? ' ant-space-compact-nav--active' : ''
                }
              >
                {t('autobuy')}
              </NavLink>
              {/*<NavLink*/}
              {/*  to="/deals/sale"*/}
              {/*  className={({ isActive }) =>*/}
              {/*    isActive ? ' ant-space-compact-nav--active' : ''*/}
              {/*  }*/}
              {/*>*/}
              {/*  {t('historySell')}*/}
              {/*</NavLink>*/}
            </Space.Compact>

            {enabled && (
              <>
                <AppAutocomplete
                  className="deals-head__autocomplete"
                  options={options}
                  size={ESelectSizeType.Large}
                  onSearch={onSearch}
                  isDefault={false}
                />

                <Select
                  className="deals-head__sort"
                  popupClassName="ant-select-dropdown-lg"
                  size={ESelectSizeType.Large}
                  suffixIcon={<ArrowDownIcon />}
                  defaultValue={filterData.quality}
                  onChange={onChangeSortQuality}
                  dropdownAlign={{ offset: [0, 0] }}
                  placeholder={t(sortQuality[0].label)}
                >
                  {sortQuality?.length &&
                    sortQuality.map((el, index) => {
                      return (
                        <Option
                          label={t(el.label)}
                          value={el.value}
                          key={index}
                        >
                          {t(el.label)}
                        </Option>
                      );
                    })}
                </Select>
                <Select
                  className="deals-head__sort"
                  popupClassName="ant-select-dropdown-lg"
                  size={ESelectSizeType.Large}
                  suffixIcon={<ArrowDownIcon />}
                  defaultValue={filterData.price}
                  onChange={onChangeSortPrice}
                  dropdownAlign={{ offset: [0, 0] }}
                  placeholder={t(sortPrice[0].label)}
                >
                  {sortPrice?.length &&
                    sortPrice.map((el, index) => {
                      return (
                        <Option
                          label={t(el.label)}
                          value={el.value}
                          key={index}
                        >
                          {t(el.label)}
                        </Option>
                      );
                    })}
                </Select>
              </>
            )}
          </div>

          <div className="deals-head__actions">
            <Button
              onClick={() => {
                onButtonSelectAll();
              }}
              size={EButtonSizeType.Large}
              className="inventory-head__btn-select"
            >
              {t('selectAll')}
            </Button>
            <Button
              onClick={() => {
                onButtonRemoveSelected();
              }}
              size={EButtonSizeType.Large}
              type={EButtonTypeType.Primary}
              className={'ant-btn-primary--error inventory-head__btn-unselect'}
            >
              {t('removeSelectedFromMarket')}
            </Button>
            <Button
              onClick={() => {
                onButtonRemoveAll();
              }}
              size={EButtonSizeType.Large}
              type={EButtonTypeType.Primary}
              className={'ant-btn-primary--error inventory-head__btn-unselect'}
            >
              {t('removeAll')}
            </Button>
            {createRequest && (
              <>
                <Button
                  type={EButtonTypeType.Primary}
                  size={EButtonSizeType.Large}
                  className="deals-head__btn-create"
                  onClick={() => {
                    onCreateTrade();
                  }}
                >
                  {t('newRequest')}
                </Button>
                <ModalTeleport
                  centered
                  modalId={'newTrade'}
                  width={'auto'}
                  modalClass={'new-save-modal__wrapper'}
                >
                  <NewTradeModal
                    onTradeCreated={onCreateAutoRequest}
                    modalId={'newTrade'}
                  />
                </ModalTeleport>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { DealsHead };
