// core
import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
//hooks
import { useQueryFetchBanner } from '../../../../hooks/api';
//store
import { useAppStore } from 'store';
// utils
import { formatPrice, getVariable } from '../../../../utils/commons';
// locales
import { useTranslation } from 'react-i18next';
// types
import { EButtonSizeType, EButtonTypeType } from 'types/units';

// components(atoms)
import { Button, Carousel } from 'antd';
import {
  CardGlowLogoIcon,
  CSBannerIcon,
  DotaBannerIcon,
  StarTrustpilotIcon,
} from 'components/icons';

// styles
import './index.scss';
import { AppLoader } from 'components/atoms';
import { ELoaderTypeType } from '../../../../components/atoms/AppLoader/types';
import { EGameType } from 'types/models';

//tempo

// func
const HomePromo = () => {
  const { t } = useTranslation();
  const { sSetGameType } = useAppStore();
  const navigate = useNavigate();
  const { variables } = useAppStore();
  const { data: bannerData, isLoading: isLoadingBannerData } =
    useQueryFetchBanner();
  const onRedirectToMarket = (provider, name) => {
    sSetGameType(provider);
    navigate(`/market?GameType=${provider}&NameSearch=${encodeURI(name)}`);
  };

  return (
    <>
      <div className="home-promo">
        <div className="home-promo__wrap">
          {!isLoadingBannerData ? (
            <Carousel autoplay={true} autoplaySpeed={70000}>
              {bannerData?.length ? (
                bannerData.map((slide, index) => {
                  return (
                    <div className="home-promo__banner-wrapper" key={index}>
                      <div className="home-promo__banner">
                        <div className="home-promo__banner-content">
                          <div className="home-promo__banner-content-title">
                            {slide.title}
                          </div>
                          <div className="home-promo__banner-content-desc">
                            {slide.description}
                          </div>
                          <div className="home-promo__banner-content-action">
                            <RouterLink to={'/market'}>
                              <Button
                                size={EButtonSizeType.Large}
                                type={EButtonTypeType.Primary}
                              >
                                {t('goToMarket')}
                              </Button>
                            </RouterLink>
                            <div className="home-promo__banner-content-action-desc">
                              <StarTrustpilotIcon />
                              {t('grade')}{' '}
                              <span>
                                {getVariable(variables, 'trustpilot_rating')}
                              </span>{' '}
                              {t('onTrustpilot')}
                            </div>
                          </div>
                        </div>
                        <div className="home-promo__banner-image">
                          <div className="home-promo__banner-image-bg">
                            <img src={slide.image} alt="Skinsty" />
                          </div>
                          {!!slide.dota_item && (
                            <div className="home-promo__banner-block home-promo__banner-block--dota">
                              <div className="home-promo__banner-icon">
                                <DotaBannerIcon />
                              </div>
                              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                              <a
                                className="home-promo__banner-card"
                                onClick={() => {
                                  onRedirectToMarket(
                                    EGameType.Dota,
                                    slide.dota_item.originalMarketName,
                                  );
                                }}
                              >
                                <div className="home-promo__banner-card-image">
                                  <img
                                    src={slide.dota_item.iconUrl}
                                    alt="Skinsty"
                                  />
                                </div>
                                <div className="home-promo__banner-card-price">
                                  $ {formatPrice(slide.dota_item.price)}
                                </div>
                                <div className="home-promo__banner-card-title">
                                  <span>
                                    {slide.dota_item.originalMarketName}
                                  </span>
                                </div>
                              </a>
                            </div>
                          )}

                          {!!slide.cs_item && (
                            <div className="home-promo__banner-block home-promo__banner-block--cs">
                              <div className="home-promo__banner-icon">
                                <CSBannerIcon />
                              </div>
                              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                              <a
                                onClick={() => {
                                  onRedirectToMarket(
                                    EGameType.CSGO,
                                    slide.cs_item.originalMarketName,
                                  );
                                }}
                                className="home-promo__banner-card"
                              >
                                <div className="home-promo__banner-card-image">
                                  <img
                                    src={slide.cs_item.iconUrl}
                                    alt="Skinsty"
                                  />
                                  <div className="home-promo__banner-card-image-logo">
                                    <CardGlowLogoIcon id={0} />
                                  </div>
                                </div>
                                <div className="home-promo__banner-card-price">
                                  $ {formatPrice(slide.cs_item.price)}
                                </div>
                                <div className="home-promo__banner-card-title">
                                  <span>
                                    {slide.cs_item.originalMarketName}
                                  </span>
                                </div>
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <></>
              )}
            </Carousel>
          ) : (
            <AppLoader type={ELoaderTypeType.Global} />
          )}
        </div>
      </div>
    </>
  );
};

export { HomePromo };
