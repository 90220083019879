// core
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import { MEDIA } from 'utils/constants';
import Cookies from 'universal-cookie';
import { useSocketDispatch, useSocketSubscribe } from 'hoc/SocketProvider';
// hooks
import {
  useMutateLogout,
  useMutationReadNotification,
  useQueryFetchNotificationList,
} from '../../../../hooks/api';
//store
import { useAuthStore, useExtStore, useAppStore } from 'store';
// locales

// types
import { EAlertType } from 'types/units';
import {
  ENotificationsType,
  ESocketEmitType,
  ESocketEventType,
} from 'types/api';

// components(atoms)
import {
  LanguageToggler,
  CurrencyToggler,
  ModalTeleport,
} from 'components/atoms';
import { LogoIcon } from 'components/icons';
import { HeaderAlert } from '../HeaderAlert';
import { HeaderApps } from '../components/HeaderApps';
import { HeaderTrades } from '../components/HeaderTrades';

// styles
import './index.scss';
import { useQueryFetchProfileInfo } from '../../../../hooks/api';
import { BlockWarning } from '../../Modals/BlockWarning';
import { HackedAttentionModal } from '../../Modals/HackedAttentionModal';
import { array } from 'yup';
import { RefillSuccess } from '../../Modals/RefillSuccess';
import { RefillError } from '../../Modals/RefillError';
import { RefillPaymentModal } from '../../Modals/RefillPaymentModal';
import { useTranslation } from 'react-i18next';
import { InstallExtensionModal } from '../../Modals/InstallExtensionModal';
// data

// func
const cookies = new Cookies(null, { path: '/' });
let extStatusInterval: NodeJS.Timeout | undefined = undefined;
const HeaderSimple = () => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const { sModalOpen } = useAppStore();
  const { accessToken, expiresAccessToken, isLoggedOut } = useAuthStore();
  const { sSetExtInstallStatus, sSetExtOnlineStatus, isExtOnline } =
    useExtStore();
  const [banStatus, setBanStatus] = useState<any | null>(null);
  const [userInfoAlert, setUserInfoAlert] = useState<any>([]);
  const [selectedUserInfoAlert, setSelectedUserInfoAlert] = useState<any>(null);
  const [isEffectRun, setIsEffectRun] = useState(false);
  const {
    data: profileData,
    isLoading: isLoadingProfileData,
    refetch: refetchProfileData,
  } = useQueryFetchProfileInfo(false);
  const { mutate: mutateReadNotification } = useMutationReadNotification();
  const { data: notificationsList } = useQueryFetchNotificationList(false);

  const { isConnected, socketEmit } = useSocketDispatch();

  const onSocketHandler = data => {
    setBanStatus(data.isActive);
  };

  const onInfoHandler = data => {
    if (
      accessToken &&
      data.userId !== undefined &&
      data.userId !== profileData?.id
    ) {
      return;
    }
    if (!accessToken && data.userId !== 0) {
      return;
    }
    if (userInfoAlert.filter(el => el.id === data.id).length === 0) {
      if (data.type === ENotificationsType.UserInfoMessage) {
        setUserInfoAlert(prevData => [
          ...prevData,
          {
            title: data.title,
            description: data.text,
            id: data.id,
            type: EAlertType.Error,
          },
        ]);
      }
      if (data.type === ENotificationsType.UserBlockMessage) {
        setUserInfoAlert(prevData => [
          ...prevData,
          {
            title: data.title,
            description: data.text,
            id: data.id,
            type: EAlertType.Warning,
          },
        ]);
      }
    }
  };

  useEffect(() => {
    if (isLoggedOut && !isEffectRun) {
      setUserInfoAlert([]);
      setIsEffectRun(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedOut]);

  useEffect(() => {
    if (notificationsList?.length) {
      const filteredInfoNotifications = notificationsList.filter(
        el =>
          el.type === ENotificationsType.UserBlockMessage ||
          el.type === ENotificationsType.UserInfoMessage,
      );
      filteredInfoNotifications.forEach(el => {
        onInfoHandler(el);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationsList]);

  const handleOpenAlertModal = data => {
    setSelectedUserInfoAlert(data);
  };
  const handleCloseAttentionModal = id => {
    if (accessToken) {
      mutateReadNotification(id);
    }
    setUserInfoAlert(prevItems => prevItems.filter(item => item.id !== id));
  };

  useSocketSubscribe(ESocketEventType.Notification, onInfoHandler);

  useSocketSubscribe(ESocketEventType.UserBan, onSocketHandler);

  useEffect(() => {
    if (profileData && isConnected) {
      socketEmit(ESocketEmitType.Join, profileData.id.toString());
    } else {
      socketEmit(ESocketEmitType.Join, ESocketEventType.Broadcast);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData, isConnected]);

  useEffect(() => {
    if (isLoggedOut) {
      if (extStatusInterval) clearInterval(extStatusInterval);
      cookies.remove('access_token', { path: '/' });
    } else if (profileData && !isLoadingProfileData) {
      if (accessToken && expiresAccessToken) {
        cookies.set('access_token', accessToken, {
          domain: window.location.hostname,
          expires: new Date(expiresAccessToken),
        });
      }

      if (extStatusInterval) clearInterval(extStatusInterval);
      checkExtStatusInstall();
      extStatusInterval = setInterval(checkExtStatusInstall, 2500);
    }
    return () => clearInterval(extStatusInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData, isLoggedOut]);

  useEffect(() => {
    if (profileData) {
      if (banStatus !== profileData?.isBanned) {
        refetchProfileData();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [banStatus]);

  const checkExtStatusInstall = () => {
    let chromeExtensionId = cookies.get('extension_installed_id');

    // @ts-ignore
    if (chromeExtensionId && chrome?.runtime) {
      // @ts-ignore

      if (accessToken && expiresAccessToken) {
        cookies.set('access_token', accessToken, {
          domain: window.location.hostname,
          expires: new Date(expiresAccessToken),
        });
      }

      let marketUserId = profileData?.id.toString() || null;
      let cookiesToken = cookies.get('access_token');
      chrome.runtime.sendMessage(
        chromeExtensionId,
        {
          from: 'MARKET',
          type: 'check_ext_init_status',
          data: {
            user_id: marketUserId,
            cookies_token: cookiesToken,
          },
        },
        response => {
          // @ts-ignore
          if (chrome.runtime.lastError) {
            sSetExtInstallStatus(false);
            sSetExtOnlineStatus(false);
          } else {
            let { is_init, is_ext_online } = response;
            sSetExtInstallStatus(is_init);
            sSetExtOnlineStatus(is_ext_online);
          }
        },
      );
    } else {
      sSetExtInstallStatus(false);
      sSetExtOnlineStatus(false);
    }
  };

  useEffect(() => {
    // sModalOpen('refillPayment');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="header-simple">
      <div className="header-simple__alerts">
        {profileData?.isBanned && (
          <HeaderAlert
            alertType={EAlertType.Warning}
            blockTime={profileData?.blockedUntil}
            isBanned={true}
          />
        )}
        {!isExtOnline && !profileData?.isBanned && (
          <HeaderAlert
            alertType={EAlertType.Warning}
            data={{
              title: t('extentionWarningTitle'),
              description: t('extentionWarningDesc'),
            }}
            onOpen={handleOpenAlertModal}
          />
        )}
        {userInfoAlert.map(el => {
          return (
            <HeaderAlert
              alertType={el.type}
              data={el}
              onOpen={handleOpenAlertModal}
              key={el.id}
            />
          );
        })}
      </div>
      <div className="header-simple__content">
        {width <= MEDIA.M1360 && (
          <RouterLink className="header-simple__logo" to={'/'}>
            <LogoIcon />
          </RouterLink>
        )}

        {width > MEDIA.M1280 && <HeaderApps />}

        <div className="header-simple__togglers">
          {width > MEDIA.M1024 && <HeaderTrades />}

          <div className="header-simple__lang">
            <LanguageToggler />
          </div>
          <div className="header-simple__curency">
            <CurrencyToggler />
          </div>
        </div>
      </div>
      <ModalTeleport
        centered
        modalId={'refillSuccess'}
        width={500}
        modalClass={'refill-success-modal__wrapper'}
      >
        <RefillSuccess modalId={'refillSuccess'} />
      </ModalTeleport>
      <ModalTeleport
        centered
        modalId={'refillError'}
        width={500}
        modalClass={'refill-error-modal__wrapper'}
      >
        <RefillError modalId={'refillError'} />
      </ModalTeleport>
      <ModalTeleport
        centered
        modalId={'blockWarning'}
        width={500}
        modalClass={'block-warning-modal__wrapper'}
      >
        <BlockWarning
          blockedUntil={profileData?.blockedUntil}
          modalId={'blockWarning'}
        />
      </ModalTeleport>
      <ModalTeleport
        centered
        modalId={'hackedAttention'}
        width={500}
        modalClass={'hacked-attention-modal__wrapper'}
      >
        <HackedAttentionModal
          onClose={handleCloseAttentionModal}
          data={selectedUserInfoAlert}
          modalId={'hackedAttention'}
        />
      </ModalTeleport>
      <ModalTeleport
        centered
        modalId={'refillPayment'}
        width={500}
        modalClass={'refill-payment-modal__wrapper'}
      >
        <RefillPaymentModal modalId={'refillPayment'} />
      </ModalTeleport>
      <ModalTeleport
        centered
        modalId={'installExtensionModal'}
        width={368}
        modalClass={'install-extension-modal__wrapper'}
      >
        <InstallExtensionModal modalId={'installExtensionModal'} />
      </ModalTeleport>
    </div>
  );
};

export { HeaderSimple };
