import { $api } from 'utils/request';
import { stringify } from 'query-string';
import {
  TWalletHistoryResponse,
  TWalletHistoryRequestParams,
  TRejectBargainRequestParams,
  TIncomeRequestResponse,
} from '../types/api/transactions';

export const GET_WALLET_HISTORY = async (
  query: TWalletHistoryRequestParams,
) => {
  const url = `market/wallet/history`;
  const response = await $api.post<TWalletHistoryResponse>(url, query);
  return response.data;
};

export const GET_BUY_BARGAIN_LIST = async (payload: any) => {
  const url = `market/bargain/outcome`;
  const response = await $api.post<TWalletHistoryResponse>(url, payload);
  return response.data;
};

export const REJECT_BARGAIN = async (payload: TRejectBargainRequestParams) => {
  const url = `market/bargain/reject`;
  const response = await $api.post(url, payload);
  return response.data;
};
export const REJECT_SELECTED_BARGAIN = async (payload: any) => {
  const url = `market/bargain/income/propose/remove/list`;
  const response = await $api.post(url, payload);
  return response.data;
};
export const REJECT_ALL_BARGAIN = async () => {
  const url = `market/bargain/income/propose/remove/all`;
  const response = await $api.post(url);
  return response.data;
};
export const GET_INCOME_BARGAIN_LIST = async (payload: any) => {
  const url = `market/bargain/income`;
  const response = await $api.post<TWalletHistoryResponse>(url, payload);
  return response.data;
};

export const GET_INCOME_REQUEST_LIST = async (sku: string) => {
  const url = `market/bargain/income/propose/${sku}`;
  const response = await $api.get<TIncomeRequestResponse>(url);
  return response.data;
};

export const REJECT_ALL_OFFERS = async (payload: any) => {
  const url = `market/bargain/rejectAll`;
  const response = await $api.post(url, payload);
  return response.data;
};

export const ACCEPT_BARGAIN = async (payload: any) => {
  const url = `market/bargain/accept`;
  const response = await $api.post(url, payload);
  return response.data;
};

export const CREATE_AUTOBUY_REQUEST = async (payload: any) => {
  const url = `market/trade/new`;
  const response = await $api.post(url, payload);
  return response.data;
};
export const GET_ACTIVE_AUTOBUY_LIST = async (payload: any) => {
  const url = `market/trade/list`;
  const response = await $api.post<any>(url, payload);
  return response.data;
};

export const REMOVE_SELECTED_AUTOBUY = async (payload: any) => {
  const url = `market/trade/remove/list`;
  const response = await $api.post<any>(url, payload);
  return response.data;
};
export const REMOVE_ALL_AUTOBUY = async () => {
  const url = `market/trade/remove/all`;
  const response = await $api.post<any>(url);
  return response.data;
};
export const REMOVE_ACTIVE_AUTOBUY = async (payload: any) => {
  const url = `market/trade/remove/${payload}`;
  const response = await $api.post<any>(url);
  return response.data;
};

export const CHANGE_AUTOBUY_PRICE = async (payload: any) => {
  const url = `market/trade/price/${payload.id}`;
  const response = await $api.post<any>(url, payload.data);
  return response.data;
};

export const CHANGE_AUTOBUY_STATUS = async (payload: any) => {
  const url = `market/trade/activate/${payload.id}`;
  const response = await $api.post<any>(url, payload.data);
  return response.data;
};
