import React, { useState } from 'react';
// types
import { Select, Switch } from 'antd';

// locale
import { useTranslation } from 'react-i18next';
// store
import { useAppStore } from 'store';
// types
import { ESelectSizeType } from 'types/units';
// components
import { ArrowDownIcon } from 'components/icons';
// styles
import './index.scss';

//data
import { priceSort, stickersSort, generalSort } from './data';
import { EGameType } from 'types/models';
const { Option } = Select;
const ProductSimilarActions = ({ onChangeFilter, gameType, canStatTrack }) => {
  const { t } = useTranslation();
  const { sModalOpen } = useAppStore();

  const [currentPriceRange, setCurrentPriceRange] = useState<string | null>(
    null,
  );
  const [currentDate, setCurrentDate] = useState<string | null>('price_lowest');
  const [currentStickerRange, setCurrentStickerRange] = useState<string | null>(
    null,
  );
  const [currentSwitch, setCurrentSwitch] = useState<boolean>(false);

  const onChangePriceRangeSelect = (value: string) => {
    setCurrentPriceRange(value);
    let selectedObject = priceSort.find(el => el.value === value);
    onChangeFilter(selectedObject?.data);
  };

  const onChangeDateSelect = (value: string) => {
    setCurrentDate(value);
    let selectedObject = generalSort.find(el => el.value === value);
    onChangeFilter(selectedObject?.data);
  };

  const onChangeStickerRangeSelect = (value: string) => {
    setCurrentStickerRange(value);
    let selectedObject = stickersSort.find(el => el.value === value);
    onChangeFilter(selectedObject?.data);
  };

  const onChangeSwitch = (value: boolean) => {
    setCurrentSwitch(value);
    onChangeFilter({ statTrack: value });
  };

  return (
    <div className="product-similar-actions">
      {/*<Select*/}
      {/*  className="product-similar-actions__select product-similar-actions__select--price ant-select--dark"*/}
      {/*  size={ESelectSizeType.Middle}*/}
      {/*  suffixIcon={<ArrowDownIcon />}*/}
      {/*  defaultValue={currentPriceRange}*/}
      {/*  onChange={onChangePriceRangeSelect}*/}
      {/*  dropdownAlign={{ offset: [0, 0] }}*/}
      {/*  placeholder={t(priceSort[0].label)}*/}
      {/*>*/}
      {/*  {priceSort?.length &&*/}
      {/*    priceSort.map((el, index) => {*/}
      {/*      return (*/}
      {/*        <Option label={t(el.label)} value={el.value} key={index}>*/}
      {/*          {t(el.label)}*/}
      {/*        </Option>*/}
      {/*      );*/}
      {/*    })}*/}
      {/*</Select>*/}
      <Select
        className="product-similar-actions__select product-similar-actions__select--date ant-select--dark"
        size={ESelectSizeType.Middle}
        suffixIcon={<ArrowDownIcon />}
        defaultValue={currentDate}
        onChange={onChangeDateSelect}
        dropdownAlign={{ offset: [0, 0] }}
        placeholder={t(generalSort[0].label)}
      >
        {generalSort?.length &&
          generalSort.map((el, index) => {
            return (
              <Option label={t(el.label)} value={el.value} key={index}>
                {t(el.label)}
              </Option>
            );
          })}
      </Select>
      {gameType === EGameType.CSGO && (
        <>
          <Select
            className="product-similar-actions__select product-similar-actions__select--scticker ant-select--dark"
            size={ESelectSizeType.Middle}
            suffixIcon={<ArrowDownIcon />}
            defaultValue={currentStickerRange}
            onChange={onChangeStickerRangeSelect}
            dropdownAlign={{ offset: [0, 0] }}
            placeholder={t(stickersSort[0].label)}
          >
            {stickersSort?.length &&
              stickersSort.map((el, index) => {
                return (
                  <Option label={t(el.label)} value={el.value} key={index}>
                    {t(el.label)}
                  </Option>
                );
              })}
          </Select>
          {canStatTrack && (
            <div className="product-similar-actions__switch">
              <Switch checked={currentSwitch} onChange={onChangeSwitch} />{' '}
              <span>{t('onlyStatTrack')}</span>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export { ProductSimilarActions };
