import React, { useEffect, useState } from 'react';
import IconRenderer from 'hoc/IconRenderer';
// utils

//hooks
import {
  useQueryFetchUserBalance,
  useQueryFetchWithdrawCountries,
  useMutationGetWithdrawMethods,
  useQueryFetchProfileInfo,
} from '../../../../hooks/api';

//locales
import { useTranslation } from 'react-i18next';

// store
import { useAppStore } from 'store/app';

// types
import {
  EInputSizeType,
  EButtonSizeType,
  EButtonTypeType,
  ESelectSizeType,
} from 'types/units';
import { EPaymentSystemType, ERefillTaxType } from 'types/models';

// components
import { Button, Input, Form, Select } from 'antd';

import {
  ArrowDownIcon,
  MasterCardIcon,
  VisaCardIcon,
  PayPalIcon,
  BitcoinIcon,
  EtheriumIcon,
} from 'components/icons';

// styles
import './index.scss';
import { formatPartsPrice, formatPrice } from '../../../../utils/commons';
import * as yup from 'yup';
import { WithdrawBalanceConfirmation } from '../WithdrawBalanceConfirmation';
import { AppLoader, ModalTeleport } from 'components/atoms';
import { ELoaderTypeType } from '../../../atoms/AppLoader/types';
const { Option } = Select;
// data
const WithdrawBalanceForm = ({ modalId }) => {
  const { t } = useTranslation();
  const { sModalOpen, sModalClose } = useAppStore();

  const [currentCard, setCurrentCard] = useState<{
    [key: string]: any;
  } | null>(null);
  const [currentCountry, setCurrentCountry] = useState<any>(null);
  const [withdrawFormPayload, setWithdrawFormPayload] = useState<any>(null);
  const [withdrawAmount, setWithdrawAmount] = useState<any>('');
  const [userEmail, setUserEmail] = useState<any>('');
  const [userEmailHint, setUserEmailHint] = useState<boolean>(false);
  const [withdrawTaxAmount, setWithdrawTaxAmount] = useState<number>(0);
  const [withdrawPayload, setWithdrawPayload] = useState<any>(null);
  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(true);

  const { data: withdrawCountriesData, isLoading: isLoadingWithdrawCountries } =
    useQueryFetchWithdrawCountries();

  const {
    data: withdrawMethods,
    isLoading: isLoadingWithdrawMethods,
    mutate: mutateWithdrawMethods,
  } = useMutationGetWithdrawMethods();
  const { data: profileData } = useQueryFetchProfileInfo(false);

  useEffect(() => {
    if (withdrawCountriesData && !isLoadingWithdrawCountries && profileData) {
      if (profileData?.country?.length) {
        if (
          withdrawCountriesData.find(
            el => el.id === profileData?.country.toUpperCase(),
          )
        ) {
          setCurrentCountry(
            withdrawCountriesData.find(
              el => el.id === profileData?.country.toUpperCase(),
            ).id,
          );
        } else {
          setCurrentCountry(withdrawCountriesData[0].id);
        }
      } else {
        setCurrentCountry(withdrawCountriesData[0].id);
      }
    }
  }, [withdrawCountriesData, isLoadingWithdrawCountries, profileData]);
  useEffect(() => {
    if (currentCountry) {
      mutateWithdrawMethods(currentCountry);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountry]);
  useEffect(() => {
    if (withdrawMethods && !isLoadingWithdrawMethods) {
      setCurrentCard(null);
      setWithdrawAmount('');
      setUserEmail('');
      setWithdrawFormPayload(null);
      setCurrentCard(withdrawMethods[0]);
    }
  }, [withdrawMethods, isLoadingWithdrawMethods]);
  useEffect(() => {
    if (currentCard) {
      let { formData } = currentCard;
      if (formData?.length) {
        setWithdrawFormPayload(
          formData.map(el => {
            return { ...el, value: '' };
          }),
        );
      } else {
        setWithdrawFormPayload(null);
      }
    }
  }, [currentCard]);

  const emailRegex = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{1,}$/;

  useEffect(() => {
    if (withdrawFormPayload?.length) {
      if (currentCard?.minimalAmount && currentCard?.minimalAmount > 0) {
        if (
          withdrawAmount &&
          Number(withdrawAmount) >= currentCard?.minimalAmount &&
          emailRegex.test(userEmail)
        ) {
          setDisabledSubmit(
            !withdrawFormPayload.every(el => el.required && el.value.length),
          );
        } else {
          setDisabledSubmit(true);
        }
      } else {
        if (withdrawAmount && emailRegex.test(userEmail)) {
          setDisabledSubmit(
            !withdrawFormPayload.every(el => el.required && el.value.length),
          );
        } else {
          setDisabledSubmit(true);
        }
      }
    } else {
      withdrawAmount && emailRegex.test(userEmail)
        ? setDisabledSubmit(false)
        : setDisabledSubmit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withdrawFormPayload, withdrawAmount, userEmail, currentCard]);

  const onChangeCountry = (value: string) => {
    setCurrentCountry(value);
  };

  const { data: userBalance, isLoading: isLoadingUserBalance } =
    useQueryFetchUserBalance(false);

  const onConfirm = () => {
    setWithdrawPayload({
      email: userEmail,
      amount: withdrawAmount,
      tax: withdrawTaxAmount,
      commission: currentCard?.tax,
      commissionType: currentCard?.taxType,
      processTime: currentCard?.processTime,
      service: currentCard?.id,
      country: currentCountry,
      payload: withdrawFormPayload?.reduce((result, item) => {
        if (item.name && 'value' in item) {
          result.push({
            name: item.name,
            value: item.value,
          });
        }
        return result;
      }, []),
    });
    sModalOpen('withdrawConfirmation');
  };

  const onChooseCard = card => {
    if (currentCard?.id !== card.id) {
      setCurrentCard(null);
      setWithdrawAmount('');
      setUserEmail('');
      setTimeout(() => {
        setCurrentCard(card);
      }, 1);
    }
  };
  const handleAmountChange = e => {
    const value = e.target.value.replace(/[^0-9.]/g, '');
    setWithdrawAmount(value);
    let tax = 0;
    if (
      currentCard?.taxType === ERefillTaxType.Percentage &&
      currentCard?.tax
    ) {
      tax = (Number(value) / 100) * currentCard.tax;
      if (tax && tax > 0) {
        setWithdrawTaxAmount(Number(tax.toFixed(2)));
      } else {
        setWithdrawTaxAmount(0);
      }
    } else {
      setWithdrawTaxAmount(currentCard?.tax);
    }
  };
  const handleEmailChange = e => {
    setUserEmailHint(true);
    setUserEmail(e.target.value);
  };
  const handleChangeFormInput = (index, e) => {
    const newValues = [...withdrawFormPayload];
    newValues[index].value = e.target.value;
    setWithdrawFormPayload(newValues);
  };

  return (
    <div className="withdraw-balance-form">
      <div className="withdraw-balance-form__grid">
        <div className="withdraw-balance-form__aside">
          <div className="withdraw-balance-form__main--title mobile">
            {t('decreaseFunds')}
          </div>
          <div className="withdraw-balance-form__main-countries">
            <Select
              className={'ant-select--light ant-select--line'}
              size={ESelectSizeType.Middle}
              suffixIcon={<ArrowDownIcon />}
              value={currentCountry}
              onChange={onChangeCountry}
              dropdownAlign={{ offset: [0, 0] }}
              popupClassName={'withdraw-balance-form__main-countries__dropdown'}
            >
              {withdrawCountriesData?.length &&
                withdrawCountriesData.map(country => {
                  return (
                    <Option
                      value={country.id}
                      label={country.label}
                      key={country.id}
                    >
                      <span
                        role="img"
                        className="lang-toggler__option-flag"
                        aria-label={country.label}
                      >
                        {country.flag}
                      </span>
                      {country.label}
                    </Option>
                  );
                })}
            </Select>
          </div>
          <div className="withdraw-balance-form__aside--list">
            {withdrawMethods?.length && !isLoadingWithdrawMethods ? (
              withdrawMethods.map(card => {
                return (
                  <div
                    className={
                      'withdraw-balance-form__aside--list-item' +
                      (card.id === currentCard?.id
                        ? ' withdraw-balance-form__aside--list-item--active'
                        : '')
                    }
                    onClick={() => onChooseCard(card)}
                    key={card.id}
                  >
                    <div className="withdraw-balance-form__aside--list-item-label">
                      {card.icon ? (
                        <img src={card.icon} alt={card.icon} />
                      ) : (
                        <></>
                      )}
                      <span>{card.label}</span>
                    </div>
                    <div className="withdraw-balance-form__aside--list-item-value">
                      {card.tax}
                      {card.taxType === ERefillTaxType.Percentage ? '%' : '$'}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="withdraw-balance-form__loader">
                <AppLoader type={ELoaderTypeType.Relative} size={32} />
              </div>
            )}
          </div>
        </div>
        <div className="withdraw-balance-form__main">
          <div className="withdraw-balance-form__main--title desktop">
            {t('decreaseFunds')}
          </div>
          {!isLoadingUserBalance && (
            <div className="withdraw-balance-form__main--balance">
              {t('yourBalance')}: ${' '}
              <span>
                {formatPartsPrice(userBalance?.amount).significantDigit}
              </span>
              .{formatPartsPrice(userBalance?.amount).fractionDigit}
            </div>
          )}
          <div className="withdraw-balance-form__main--form">
            <div className="withdraw-balance-form__main--form-item">
              <div className="withdraw-balance-form__main--form-item-label">
                {t(`withdrawForm.input.amount`)}{' '}
              </div>
              <Input
                className={'ant-input--borderless'}
                size={EInputSizeType.Large}
                name={'amount'}
                type={'number'}
                value={withdrawAmount}
                onChange={e => handleAmountChange(e)}
                placeholder={t('withdrawForm.placeholder.amount')}
              />
              {currentCard?.minimalAmount ? (
                <div className="withdraw-balance-form__main--form-item-hint">
                  {t('withdrawForm.input.amountMinimal')} $
                  {currentCard?.minimalAmount}
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="withdraw-balance-form__main--form-item">
              <div className="withdraw-balance-form__main--form-item-label">
                {t(`withdrawForm.input.email`)}{' '}
              </div>
              <Input
                className={'ant-input--borderless'}
                size={EInputSizeType.Large}
                name={'email'}
                type={'email'}
                value={userEmail}
                onChange={e => handleEmailChange(e)}
                placeholder={t('withdrawForm.placeholder.email')}
              />
              {userEmailHint && !emailRegex.test(userEmail) ? (
                <div className="withdraw-balance-form__main--form-item-hint">
                  {t('validations.email')}
                </div>
              ) : (
                <></>
              )}
            </div>
            {withdrawFormPayload?.length &&
              withdrawFormPayload.map((input, index) => {
                return (
                  <div
                    key={input.name}
                    className="withdraw-balance-form__main--form-item"
                  >
                    <div className="withdraw-balance-form__main--form-item-label">
                      {input.label}
                    </div>
                    <Input
                      required={input.required}
                      className={'ant-input--borderless'}
                      size={EInputSizeType.Large}
                      name={input.name}
                      type={input.type}
                      onChange={e => handleChangeFormInput(index, e)}
                      placeholder={input.placeholder}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div className="withdraw-balance-form__footer">
        <div className="withdraw-balance-form__footer--value">
          <h3>
            {t('total')}: $ {formatPrice(Number(withdrawAmount))}
          </h3>
          <span>
            {t('commission')}: $ {formatPrice(withdrawTaxAmount)}
          </span>
        </div>
        <div className="withdraw-balance-form__footer--actions">
          <Button
            type={EButtonTypeType.Primary}
            size={EButtonSizeType.Large}
            disabled={disabledSubmit}
            onClick={() => {
              onConfirm();
            }}
          >
            {t('decreaseFundsBtn')}
          </Button>
        </div>
      </div>
      <ModalTeleport centered modalId={'withdrawConfirmation'} width={785}>
        <WithdrawBalanceConfirmation
          formData={withdrawPayload}
          modalId={'withdrawConfirmation'}
        />
      </ModalTeleport>
    </div>
  );
};

export { WithdrawBalanceForm };
