import { useMutation, useQuery } from '@tanstack/react-query';

//types

// services
import { REMOVE_ALL_AUTOBUY } from '../../../api/transactions';

export const useMutationRemoveAllAutobuy = () => {
  return useMutation(REMOVE_ALL_AUTOBUY, {
    onSuccess: data => {
      return data;
    },
    onError: (err: Error) => {
      const message = err
        ? err?.message
        : 'Error while get active autobuy list';
      console.error(message);
      return message;
    },
  });
};
