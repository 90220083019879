import React from 'react';
// core
// utils
// locales
// store
// types
import { ECardSizeType, IPropsType } from './types';

// components(atoms)
/*@ts-ignore*/
import DuckImage from 'assets/img/EmptyDuck.png';
/*@ts-ignore*/
import ErrorDuckImage from 'assets/img/ErrorDuck.png';
// styles
import './index.scss';

// data

// func
const EmptyCard = ({
  title,
  description,
  isHideImg,
  size,
  isError,
}: IPropsType) => {
  return (
    <div
      className={
        'empty-card' +
        (size === ECardSizeType.Small ? ' empty-card--small' : '') +
        (size === ECardSizeType.Minimal ? ' empty-card--minimal' : '') +
        (isError ? ' empty-card--error' : ' empty-card--empty')
      }
    >
      {!isHideImg && (
        <img src={isError ? ErrorDuckImage : DuckImage} alt={''} />
      )}
      {title && <div className="empty-card__title">{title}</div>}
      {description && <div className="empty-card__text">{description}</div>}
    </div>
  );
};

EmptyCard.defaultProps = {
  size: ECardSizeType.Default,
  isError: false,
} as { size: ECardSizeType };

export { EmptyCard };
