import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

type WalletState = {
  refillPaymentForm: any;
  setRefillPaymentForm: (payload: any) => void;
  resetRefillPaymentForm: (payload: any) => void;
};

const useWalletStore = create(
  devtools(
    persist<WalletState>(
      set => ({
        refillPaymentForm: null,
        setRefillPaymentForm: (payload: any) => {
          set(state => ({
            refillPaymentForm: payload,
          }));
        },
        resetRefillPaymentForm: () => {
          set(state => ({
            refillPaymentForm: null,
          }));
        },
      }),
      { name: 'profile' },
    ),
  ),
);
export { useWalletStore };
