import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { POST_LOGOUT } from 'api/auth';

import { useAuthStore } from 'store';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
export const useMutateLogout = () => {
  const { t } = useTranslation();
  const { removeAccessToken } = useAuthStore();

  return useMutation(POST_LOGOUT, {
    onSuccess: () => {
      const cookies = new Cookies(null, { path: '/' });

      removeAccessToken();
      localStorage.removeItem('access_token');
      localStorage.removeItem('expires_access_token');
      localStorage.removeItem('refresh_token');

      cookies.remove('access_token', { path: '/' });

      let chromeExtensionId = cookies.get('extension_installed_id');
      let cookiesToken = cookies.get('access_token');

      if (chromeExtensionId && chrome?.runtime) {
        chrome.runtime.sendMessage(
          chromeExtensionId,
          {
            from: 'MARKET',
            type: 'logout_market',
            data: { cookies_token: cookiesToken },
          },
          response => {
            if (chrome.runtime.lastError) {
              console.log('logout_market error', chrome.runtime.lastError);
            }
          },
        );
      }

      toast.success(t('toastSuccessLogout'));
    },
    onError: (err: AxiosError) => {
      const message = err ? err?.message : 'Error while trying Logout';
      console.error(message);
      localStorage.removeItem('access_token');
      localStorage.removeItem('expires_access_token');
      return message;
    },
  });
};
