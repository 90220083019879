import React, { useEffect, useState } from 'react';

// utils
import { formatPartsPrice, formatPrice } from '../../../../utils/commons';
import * as yup from 'yup';
//locales
import { useTranslation } from 'react-i18next';

//hooks
import {
  useMutationCreateWithdrawRequest,
  useQueryFetchProfileInfo,
} from '../../../../hooks/api';

// store
import { useAppStore } from 'store';

// types
import { EButtonSizeType, EButtonTypeType } from 'types/units';

// components
import { Button } from 'antd';
import { ArrowLeftLightIcon } from 'components/icons';
/*@ts-ignore*/
// import WithdrawBalanceConfirmationBg from 'assets/img/WithdrawBalanceConfirmationBg.png';
import WithdrawBalanceConfirmationBg from 'assets/img/WithdrawBalanceConfirmationNew.png';

// styles
import './index.scss';
import { WithdrawBalancePassword } from '../WithdrawBalancePassword';
import { ModalTeleport } from 'components/atoms';
import { toast } from 'react-toastify';
import { ERefillTaxType } from 'types/models';
import { DashboardModalCreatePaymentPassword } from '../../../../containers/user';

// data
const formatCard = card => {
  if (card.length >= 8) {
    return `${card.slice(0, 4)}....${card.slice(-4)}`;
  } else {
    return card;
  }
};
const WithdrawBalanceConfirmation = ({ formData, modalId }) => {
  const { t } = useTranslation();
  const { sModalOpen, sModalClose } = useAppStore();
  const [withdrawAddress, setWithdrawAddress] = useState<any>(null);

  const {
    data: withdrawRequestData,
    isLoading: isLoadingWithdrawRequest,
    mutate: mutateWithdrawRequest,
  } = useMutationCreateWithdrawRequest();

  const { data: profileData } = useQueryFetchProfileInfo(false);
  const onConfirm = () => {
    if (profileData?.paymentPassword) {
      sModalOpen('withdrawPassword');
    } else {
      sModalOpen('withdrawPasswordCreate');
    }
  };

  const onPasswordCreated = () => {
    sModalOpen('withdrawPassword');
  };
  const onDeclinePaymentPass = () => {
    const { amount, service, email, payload, country } = formData;
    const withdrawPayloadData = {
      data: {
        amount: Number(amount),
        currency: service,
        password: null,
        userConfirm: true,
        email: email,
        form: payload,
      },
      country: country,
    };
    mutateWithdrawRequest(withdrawPayloadData);
  };
  const onDecline = id => {
    sModalClose(id);
    sModalOpen('withdrawForm');
  };

  useEffect(() => {
    if (formData && formData.payload?.length) {
      if (formData.payload.find(el => el.name === 'address')) {
        setWithdrawAddress(
          formData.payload.find(el => el.name === 'address').value,
        );
      }
    }
  }, [formData]);

  useEffect(() => {
    if (!isLoadingWithdrawRequest && withdrawRequestData) {
      sModalClose(modalId);
      sModalClose('withdrawForm');
      toast.success(t('toastWithdrawRequest'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withdrawRequestData, isLoadingWithdrawRequest]);
  const onSubmitPassword = data => {
    const { amount, service, email, payload, country } = formData;
    const withdrawPayloadData = {
      data: {
        amount: Number(amount),
        currency: service,
        password: data.password,
        userConfirm: false,
        email: email,
        form: payload,
      },
      country: country,
    };
    mutateWithdrawRequest(withdrawPayloadData);
  };
  return (
    <div className="withdraw-balance-confirmation">
      <div className="withdraw-balance-confirmation__head">
        <div className="withdraw-balance-confirmation__head--bg">
          <img src={WithdrawBalanceConfirmationBg} alt="Skinsty" />
        </div>
        <div className="withdraw-balance-confirmation__head--action">
          <Button
            type={EButtonTypeType.Primary}
            className={'ant-btn-ghost--white'}
            ghost
            size={EButtonSizeType.Large}
            onClick={() => {
              onDecline(modalId);
            }}
          >
            <ArrowLeftLightIcon />
            {t('change')}
          </Button>
        </div>
        <div className="withdraw-balance-confirmation__head--content">
          <div className="withdraw-balance-confirmation__head--content-left">
            <h3>{t('decreaseFunds')}</h3>
            <p>
              {t('checkDataForTransaction')} {formData.processTime}
            </p>
          </div>
          <div className="withdraw-balance-confirmation__head--content-right">
            <p>{t('decreaseAmount')}</p>
            <div className="withdraw-balance-confirmation__head--content-right-sum">
              ${' '}
              <span>
                {formatPartsPrice(Number(formData.amount)).significantDigit}
                <span>
                  .{formatPartsPrice(Number(formData.amount)).fractionDigit}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="withdraw-balance-confirmation__body">
        <div className="withdraw-balance-confirmation__body--card">
          <div className="withdraw-balance-confirmation__body--card-title">
            $ {formatPrice(Number(formData.amount) - Number(formData.tax))}
          </div>
          <div className="withdraw-balance-confirmation__body--card-label">
            {t('willBeCredited')}
          </div>
        </div>
        <div className="withdraw-balance-confirmation__body--card">
          <div className="withdraw-balance-confirmation__body--card-title">
            $ {formatPrice(Number(formData.tax))}{' '}
            <span>
              ({formData.commission}{' '}
              {formData.commissionType === ERefillTaxType.Percentage
                ? '%'
                : '$'}
              )
            </span>
          </div>
          <div className="withdraw-balance-confirmation__body--card-label">
            {t('commission')}
          </div>
        </div>
        {withdrawAddress?.length && (
          <div className="withdraw-balance-confirmation__body--card">
            <div className="withdraw-balance-confirmation__body--card-title withdraw-balance-confirmation__body--card-title--wide">
              {withdrawAddress}
            </div>
            <div className="withdraw-balance-confirmation__body--card-label">
              {t('withdrawAddress')}
            </div>
          </div>
        )}
      </div>
      <div className="withdraw-balance-confirmation__actions">
        <Button
          block
          type={EButtonTypeType.Primary}
          size={EButtonSizeType.Large}
          disabled={isLoadingWithdrawRequest}
          onClick={() => {
            onConfirm();
          }}
        >
          {t('decreaseFundsBtnConfirm')} ${' '}
          {formatPrice(Number(formData.amount) - Number(formData.tax))}
        </Button>
      </div>
      <ModalTeleport centered modalId={'withdrawPassword'} width={427}>
        <WithdrawBalancePassword
          onSubmitPassword={onSubmitPassword}
          modalId={'withdrawPassword'}
        />
      </ModalTeleport>
      <ModalTeleport centered modalId={'withdrawPasswordCreate'} width={427}>
        <DashboardModalCreatePaymentPassword
          isPayment={true}
          onCreate={onPasswordCreated}
          onDecline={onDeclinePaymentPass}
          modalId={'withdrawPasswordCreate'}
        />
      </ModalTeleport>
    </div>
  );
};

export { WithdrawBalanceConfirmation };
