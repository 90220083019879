import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Typography, Button, Input } from 'antd';
// utils

// store
import { useAppStore, useWalletStore } from 'store';

// types
import { EAlertType, EButtonSizeType, EButtonTypeType } from 'types/units';

// components

// styles
import './index.scss';

// data
/*@ts-ignore*/
import Maskot from '../../../../assets/img/maskot.png';
import { CopyIcon } from 'components/icons';
import { ECardSizeType } from '../../Cards/EmptyCard/types';
import { EmptyCard } from 'components/features';
import { useTranslation } from 'react-i18next';
import { useSocketSubscribe } from '../../../../hoc/SocketProvider';
import { ENotificationsType, ESocketEventType } from 'types/api';
import { ERefillResponseType } from 'types/models';

const RefillPaymentModal = ({ modalId }) => {
  const { t } = useTranslation();
  const { sModalClose, sModalOpen } = useAppStore();
  const { refillPaymentForm, resetRefillPaymentForm } = useWalletStore();
  const onCopyLink = () => {
    let textarea = document.createElement('textarea');
    textarea.value = refillPaymentForm.data?.address;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  };
  const onSocketNotificationHandler = data => {
    console.log('onSocketNotificationHandler', data);
    if (data.type === ENotificationsType.BalanceIncrease) {
      sModalClose(modalId);
      sModalOpen('refillSuccess');
    }
    if (data.type === ENotificationsType.BalanceIncreaseFail) {
      sModalClose(modalId);
      sModalOpen('refillError');
    }
  };
  useSocketSubscribe(
    ESocketEventType.Notification,
    onSocketNotificationHandler,
  );

  return (
    <div className="refill-payment refill-payment-modal">
      {refillPaymentForm.data ? (
        <>
          {refillPaymentForm.type === ERefillResponseType.JSON ? (
            <div className="refill-payment__form">
              <div className="refill-payment__form-head">
                <div className="refill-payment__form-title">
                  {t('refillPaymentModal.title')}
                </div>
              </div>
              <div className="refill-payment__form-body">
                {refillPaymentForm.data?.logo &&
                  refillPaymentForm.data?.logo.length && (
                    <div className="refill-payment__form-body__logo">
                      <img
                        src={refillPaymentForm.data?.logo}
                        alt="Payment logo"
                      />
                    </div>
                  )}
                {refillPaymentForm.data?.status && (
                  <div className="refill-payment__form-body__text">
                    {refillPaymentForm.data?.status}
                  </div>
                )}
                <div className="refill-payment__form-body__qr">
                  <img src={refillPaymentForm.data?.qr} alt="Payment QR" />
                </div>
                <div className="refill-payment__form-body__text">
                  {t('refillPaymentModal.transfer')}
                </div>
                <div className="refill-payment__form-body__input">
                  <Input
                    className={'ant-input--borderless'}
                    readOnly
                    value={`${refillPaymentForm.data?.amount} ${refillPaymentForm.data?.currency}`}
                  />
                </div>
                <div className="refill-payment__form-body__text">
                  {t('refillPaymentModal.on')}
                </div>
                <div className="refill-payment__form-body__input">
                  <Input
                    readOnly
                    value={refillPaymentForm.data?.address}
                    className="ant-input-group-wrapper--custom-addon"
                    addonAfter={
                      <Button
                        size={EButtonSizeType.Middle}
                        className="ant-btn-text--primary"
                        type="text"
                        onClick={() => {
                          onCopyLink();
                        }}
                      >
                        <CopyIcon key="copy-icon" />
                      </Button>
                    }
                  />
                </div>
                <div className="refill-payment__form-body__text">
                  {t('refillPaymentModal.desc1')}{' '}
                  {refillPaymentForm.data?.expireLimit}{' '}
                  {t('refillPaymentModal.desc2')}
                </div>
              </div>
            </div>
          ) : (
            <div className="refill-payment__form">
              <div className="refill-payment__form-head">
                <div className="refill-payment__form-title">
                  {t('refillPaymentModal.title')}
                </div>
              </div>
              <div
                className="refill-payment__form-body"
                dangerouslySetInnerHTML={{
                  __html: refillPaymentForm.data,
                }}
              ></div>
            </div>
          )}
        </>
      ) : (
        <div className="refill-payment__form">
          <EmptyCard
            size={ECardSizeType.Small}
            title={t('emptyCartTitle')}
            description={t('nothingFound')}
          />
        </div>
      )}
    </div>
  );
};

export { RefillPaymentModal };
