import React, { useEffect, useRef, useState } from 'react';
import { Badge, Button } from 'antd';
import { MEDIA } from 'utils/constants';
import { useWindowSize } from 'react-use';
import { formatPrice } from 'utils/commons';
import { toast } from 'react-toastify';
import VirtualList from 'rc-virtual-list';
import { useNavigate } from 'react-router-dom';

//locales
import { useTranslation } from 'react-i18next';

// store
import { useAppStore, useExtStore, useInventoryStore } from 'store';
import {
  useMutationInventoryAllProducts,
  useMutationInventoryItemsToMarket,
} from 'hooks/api';
// types
import {
  EBadgeStatusType,
  EButtonSizeType,
  EButtonTypeType,
  EInventoryAsideType,
} from 'types/units';

// components
import { EmptyCard } from 'components/features';
import { InventoryExposeItem } from './InventoryExposeItem';
import { CloseBigIcon } from 'components/icons';
import { AppLoader } from 'components/atoms';
import { InventoryExposeChart } from './InventoryExposeChart';

// styles
import './index.scss';

//data
const InventoryExpose = ({ onExposedMultiple }) => {
  const { t } = useTranslation();
  const { width: windowWidth } = useWindowSize();
  const { gameType, commission_percentage, sModalOpen } = useAppStore();
  const { isExtInstalled } = useExtStore();

  const listRef = useRef<any>(null);
  const navigate = useNavigate();
  const {
    inventoryProduct,
    inventoryExpose,
    sSetInventoryAside,
    sSetInventoryExpose,
  } = useInventoryStore();

  const {
    mutate: mutateInventoryAll,
    data: dataInventoryAll,
    isLoading: isLoadingInventoryAll,
  } = useMutationInventoryAllProducts();

  const {
    mutate: mutateInventoryToMarket,
    isLoading: isLoadingInventoryToMarket,
    isSuccess: isSuccessInventoryToMarket,
  } = useMutationInventoryItemsToMarket();

  const [summary, setSummary] = useState<{
    count: number;
    total: number;
    netTotal: number;
  }>({
    count: 0,
    total: 0,
    netTotal: 0,
  });

  useEffect(() => {
    if (dataInventoryAll?.length) {
      createExposeList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataInventoryAll]);

  useEffect(() => {
    let summaryData = inventoryExpose.reduce(
      (acc, item) => {
        return {
          count: acc.count + item._saleCount,
          total: acc.total + item.price * item._saleCount,
          netTotal: acc.netTotal + item._netPrice * item._saleCount,
        };
      },
      { count: 0, total: 0, netTotal: 0 },
    );

    setSummary(summaryData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(inventoryExpose)]);

  useEffect(() => {
    if (!isLoadingInventoryToMarket && isSuccessInventoryToMarket) {
      onExposedMultiple();
      toast.success(t('toastSuccessPlacementOnMarket'));
      navigate(`/inventory/sales`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingInventoryToMarket, isSuccessInventoryToMarket]);
  const onExposeProducts = () => {
    if (isExtInstalled) {
      let productsToExpose = inventoryExpose.reduce((acc, item) => {
        let items: any = [];
        if (item._saleCount > 1) {
          for (let i = 0; i < item._saleCount; i++) {
            items.push({
              itemId: item.groupIds[i],
              price: item.price,
            });
          }
        } else {
          items.push({
            itemId: item.id,
            price: item.price,
          });
        }
        return [...acc, ...items];
      }, []);
      mutateInventoryToMarket(productsToExpose);
    } else {
      sModalOpen('installExtension');
    }
  };

  const createExposeList = () => {
    let exposeList = dataInventoryAll!.map(exposeItem => {
      let itemInExpose = inventoryExpose.find(
        item => item.id === exposeItem.id,
      );

      let additionalData: {};

      if (itemInExpose) {
        additionalData = {
          price: itemInExpose.price,
          _saleCount: itemInExpose.count,
          _netPrice: formatPrice(
            itemInExpose.price - itemInExpose.price * commission_percentage,
            2,
            false,
          ),
        };
      } else {
        let exposeItemPrice = exposeItem.price
          ? exposeItem.price
          : exposeItem.marketPrice;

        exposeItemPrice = formatPrice(exposeItemPrice, 2, false) as number;

        additionalData = {
          price: exposeItemPrice,
          _saleCount: exposeItem.count,
          _netPrice: formatPrice(
            exposeItemPrice - exposeItemPrice * commission_percentage,
            2,
            false,
          ),
        };
      }

      return {
        ...exposeItem,
        ...additionalData,
      };
    });

    sSetInventoryExpose(exposeList);
  };

  const onExposeAll = () => {
    if (dataInventoryAll?.length) {
      createExposeList();
    } else {
      mutateInventoryAll({ provider: gameType });
    }
  };

  const onDeleteAll = () => {
    sSetInventoryExpose([]);
  };

  const onClose = () => {
    sSetInventoryAside(
      inventoryProduct
        ? EInventoryAsideType.Product
        : EInventoryAsideType.Empty,
    );
  };

  const onDeleteItem = (id: any) => {
    let newExposeData = inventoryExpose.filter(item => item.id !== id);
    sSetInventoryExpose(newExposeData);
  };

  return (
    <div className="inventory-expose">
      {(isLoadingInventoryToMarket || isLoadingInventoryAll) && (
        <div className="inventory-expose__loading">
          <AppLoader />
        </div>
      )}
      <div className="inventory-expose__head">
        <div className="inventory-expose__head-title">{t('sellTitle')}</div>
        <div className="inventory-expose__head-count">
          <Badge status={EBadgeStatusType.Default} />
          {t('items')} ({summary.count})
        </div>
        <div className="inventory-expose__head-actions">
          <Button size={EButtonSizeType.Small} onClick={() => onExposeAll()}>
            {t('selectAll')}
          </Button>
          {summary.count > 0 ? (
            <Button
              type={EButtonTypeType.Primary}
              className="ant-btn-primary--error"
              size={EButtonSizeType.Small}
              onClick={() => {
                onDeleteAll();
              }}
            >
              {t('resetAll')}
            </Button>
          ) : (
            windowWidth > MEDIA.M1024 && (
              <CloseBigIcon
                className="inventory-expose__head-btn-close"
                onClick={() => onClose()}
              />
            )
          )}
        </div>
      </div>
      <div className="inventory-expose__content" ref={listRef}>
        {inventoryExpose?.length > 0 ? (
          <>
            {windowWidth > MEDIA.M1024 ? (
              <VirtualList
                data={inventoryExpose}
                height={listRef?.current ? listRef?.current.clientHeight : 0}
                itemHeight={255}
                itemKey="sku"
                className="inventory-expose__scroll"
              >
                {(item: any) => (
                  <div>
                    <InventoryExposeItem
                      data={item}
                      windowWidth={windowWidth}
                      onDelete={onDeleteItem}
                    />
                    {inventoryExpose?.length === 1 && (
                      <InventoryExposeChart exposeItem={inventoryExpose[0]} />
                    )}
                  </div>
                )}
              </VirtualList>
            ) : (
              <div className="inventory-expose__scroll">
                {inventoryExpose.map(item => (
                  <InventoryExposeItem
                    key={item.id}
                    data={item}
                    windowWidth={windowWidth}
                    onDelete={onDeleteItem}
                  />
                ))}
                {inventoryExpose?.length === 1 && (
                  <InventoryExposeChart exposeItem={inventoryExpose[0]} />
                )}
              </div>
            )}
          </>
        ) : (
          <div className="inventory-expose__empty">
            <EmptyCard
              title={t('emptyInventoryTitle')}
              description={t('emptyInventoryDesc')}
            />
          </div>
        )}
      </div>
      {windowWidth > MEDIA.M1024 && (
        <div className="inventory-expose__footer">
          <div className="inventory-expose__footer-info">
            <ul className="inventory-expose__footer-summary">
              <li>
                {t('totalWithCommission')}:{' '}
                <span>
                  {summary.total ? `$${formatPrice(summary.total)}` : '$ 0.00'}
                </span>
              </li>
              <li>
                {t('userIncome')}:{' '}
                <span>
                  {summary.netTotal
                    ? `$${formatPrice(summary.netTotal)}`
                    : '$ 0.00'}
                </span>
              </li>
            </ul>
          </div>
          <Button
            className="inventory-expose__btn-expose"
            size={EButtonSizeType.Large}
            type={EButtonTypeType.Primary}
            disabled={isLoadingInventoryToMarket || summary.count === 0}
            onClick={() => {
              onExposeProducts();
            }}
          >
            {t('placeItems')}
          </Button>
        </div>
      )}
    </div>
  );
};

export { InventoryExpose };
