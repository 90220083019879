import React, { useEffect, useMemo, useState } from 'react';
// types
import { Button } from 'antd';
import { useLocation } from 'react-router-dom';
// locale
import { useTranslation } from 'react-i18next';
// store
import { useAppStore, useAuthStore, useExtStore } from 'store';
//hooks
import { useQueryFetchProductTrades } from '../../../../hooks/api';
// types
import { EButtonTypeType } from 'types/units';

// components
import { ModalTeleport } from 'components/atoms';
import { NewTradeModal, RequestComparisonModal } from 'components/features';
import { ProductRequestCard } from './ProductRequestCard';
// styles
import './index.scss';
import { EGameType } from 'types/models';

//data

const ProductRequests = ({ product, onLoaded }) => {
  const { t } = useTranslation();
  const { accessToken: isAuth } = useAuthStore();
  const { sModalOpen, sModalClose } = useAppStore();
  const { isExtOnline } = useExtStore();
  const location = useLocation();

  const [selectedRequestData, setSelectedRequestData] = useState<any>(null);

  const productName = useMemo(() => {
    return product.marketName.second.length
      ? product.marketName.first + ' | ' + product.marketName.second
      : product.marketName.first;
  }, [product]);

  const exterior = useMemo(() => {
    return product.provider === EGameType.CSGO
      ? product.tag?.exterior?.value
      : null;
  }, [product]);

  const { data: requestList, isLoading: requestListLoading } =
    useQueryFetchProductTrades({ nameSearch: productName, exterior: exterior });
  useEffect(() => {
    if (!requestListLoading && requestList) {
      onLoaded();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestList, requestListLoading]);

  useEffect(() => {
    sModalClose('requestComparison');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  const onCreateRequest = () => {
    if (isAuth) {
      if (!isExtOnline) {
        sModalOpen('installExtensionModal');
      } else {
        sModalOpen('newTrade');
      }
    } else {
      sModalOpen('steamAuth');
    }
  };

  const onLoadDeals = data => {
    setSelectedRequestData(data);
    sModalOpen('requestComparison');
  };

  return (
    <div className="product-requests" id="purchase_requests">
      <div className="product-requests__head">
        <div className="product-requests__title">{t('productRequest')}</div>
        <div className="product-requests__actions">
          <Button
            type={EButtonTypeType.Primary}
            className="product-requests__btn-create"
            onClick={() => {
              onCreateRequest();
            }}
          >
            {t('productCreateRequest')}
          </Button>
        </div>
      </div>
      <div className="product-requests__grid">
        {!requestListLoading && requestList?.length ? (
          requestList.map((el, index) => {
            return (
              <ProductRequestCard
                key={index}
                request={el}
                name={productName}
                onLoaded={onLoadDeals}
              />
            );
          })
        ) : (
          <div className="product-requests__grid--empty">
            {t('emptyRequestDesc')}
          </div>
        )}
      </div>
      <ModalTeleport
        centered
        modalId={'newTrade'}
        width={'auto'}
        modalClass={'new-save-modal__wrapper'}
      >
        <NewTradeModal
          onTradeCreated={() => {}}
          modalId={'newTrade'}
          data={product}
        />
      </ModalTeleport>
      <ModalTeleport
        centered
        modalId={'requestComparison'}
        width={1300}
        modalClass={'request-trade-modal__wrapper'}
      >
        {productName && selectedRequestData && (
          <RequestComparisonModal
            name={productName}
            modalId={'requestComparison'}
            requestData={selectedRequestData}
          />
        )}
      </ModalTeleport>
    </div>
  );
};

export { ProductRequests };
