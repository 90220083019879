export enum ENotificationsType {
  BalanceIncrease = 'balanceIncrease',
  BalanceIncreaseFail = 'balanceIncreaseFail',
  BalanceDecrease = 'balanceDecrease',
  BalanceCashOut = 'balanceCashOut',
  BalanceRequestCashOut = 'balanceRequestCashOut',
  SupportTicket = 'supportTicket',
  ItemReceive = 'itemReceive',
  ItemRequestToSend = 'itemRequestToSend',
  ItemBought = 'itemBought',
  ItemSold = 'itemSold',
  ItemPropose = 'itemPropose',
  ItemProposeRejected = 'itemProposeRejected',
  ItemProposeRejectedBuyer = 'itemProposeRejectedBuyer',
  ItemRequestBuy = 'itemRequestBuy',
  InventoryUpdated = 'inventoryUpdated',
  ItemRequestToAccept = 'itemRequestToAccept',
  ItemSellFailed = 'itemSellFailed',
  UserInfoMessage = 'userInfoMessage',
  UserBlockMessage = 'userBlockMessage',
}

export type TNotificationListResponse = [
  {
    title: string;
    text: string;
    status: string;
    info: {
      balance: number;
      amount: number;
      itemId: number;
      fromUserId: number;
      toUserId: number;
      discountAmount: number;
      timeLeftInSeconds: number;
      leftAdditionInfo: string;
      rightAdditionInfo: string;
    };
    infoLink: any;
    type: string;
    action: [
      {
        title: string;
        type: ENotificationsActionType | string;
        link: string;
      },
    ];
    date: Date;
  },
];

export enum ENotificationsActionType {
  Accept = 'accept',
  Reject = 'reject',
}

export type TNotificationsReadRequestParams = string;
