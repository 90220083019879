import React, { useEffect, useRef, useState } from 'react';
import { Avatar, Badge, Button, Dropdown, Popover, Space } from 'antd';
import { NavLink } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import { MEDIA } from 'utils/constants';
import { Howl } from 'howler';
// locale
import { useTranslation } from 'react-i18next';
//hooks
import {
  useQueryFetchProfileInfo,
  useMutateLogout,
  useQueryFetchNotificationList,
} from '../../../../../hooks/api';
import { useSocketSubscribe } from '../../../../../hoc/SocketProvider';

// store
import { useAppStore, useAuthStore } from 'store';
// types
import { EButtonTypeType, EAvatarSizeType } from 'types/units';
import { ENotificationsType, ESocketEventType } from 'types/api';

// components
import { ArrowDownIcon, SteamIcon, NotificationIcon } from 'components/icons';

import { HeaderNotices } from '../../components/HeaderNotices';
import { ModalTeleport } from 'components/atoms/ModalTeleport';
import { SteamAuthModal } from 'components/features';

// styles
import './index.scss';
import { getVariable } from '../../../../../utils/commons';

//data

const HeaderProfile = () => {
  const { t } = useTranslation();
  const { sModalOpen, variables } = useAppStore();
  const { width } = useWindowSize();
  const { accessToken: isAuth } = useAuthStore();
  const [isNotificationsOpen, setIsNotificationsOpen] =
    useState<boolean>(false);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [enableFetchNotifications, setEnableFetchNotifications] =
    useState<boolean>(false);

  const sound = useRef<any>(null);

  const [notifications, setNotifications] = useState<any>([]);

  const { data: notificationList } = useQueryFetchNotificationList(
    enableFetchNotifications,
  );

  useEffect(() => {
    if (notificationList) {
      setNotifications(
        notificationList.filter(
          item =>
            item.type !== ENotificationsType.UserInfoMessage &&
            item.type !== ENotificationsType.UserBlockMessage,
        ),
      );
    }
  }, [notificationList]);

  const { mutate: mutateLogout } = useMutateLogout();

  const { data: profileData, isLoading: isLoadingProfileData } =
    useQueryFetchProfileInfo(false);

  const onSocketHandler = data => {
    if (data) {
      if (
        [
          ENotificationsType.ItemPropose,
          ENotificationsType.ItemRequestToSend,
          ENotificationsType.ItemBought,
          ENotificationsType.ItemRequestToAccept,
          ENotificationsType.ItemSold,
          ENotificationsType.SupportTicket,
        ].indexOf(data.type) > -1
      ) {
        sound.current.play();
      }
      if (
        [
          ENotificationsType.UserBlockMessage,
          ENotificationsType.UserInfoMessage,
        ].indexOf(data.type) < 0
      ) {
        setNotifications([data, ...notifications]);
      }
    }
  };

  useSocketSubscribe(ESocketEventType.Notification, onSocketHandler);

  useEffect(() => {
    if (isAuth && profileData && variables?.length) {
      sound.current = new Howl({
        src: [getVariable(variables, 'notification')],
        volume: 1,
        loop: false,
      });
      setEnableFetchNotifications(true);
    }
  }, [isAuth, profileData, variables]);

  useEffect(() => {
    return () => {
      if (sound) {
        sound.current?.unload();
      }
    };
  }, []);

  const onLogin = () => {
    sModalOpen('steamAuth');
  };
  const onLogout = () => {
    mutateLogout();
    setIsMenuOpen(false);
  };

  const onReadNoticeComplete = list => {
    setNotifications(list);
  };

  const items = [
    {
      key: '1',
      label: (
        <NavLink to={'/dashboard/profile'} onClick={() => setIsMenuOpen(false)}>
          {t('settings')}
        </NavLink>
      ),
    },
    {
      key: '2',
      label: (
        <NavLink to={'/history/purchase'} onClick={() => setIsMenuOpen(false)}>
          {t('history')}
        </NavLink>
      ),
    },
    {
      key: '3',
      label: (
        <NavLink
          to={'/dashboard/referral'}
          onClick={() => setIsMenuOpen(false)}
        >
          {t('refSystem')}
        </NavLink>
      ),
    },
    {
      key: '4',
      label: (
        <NavLink to={'/deals/trade'} onClick={() => setIsMenuOpen(false)}>
          {t('bargain')}
        </NavLink>
      ),
    },
    {
      key: '5',
      label: (
        <NavLink
          to={'/dashboard/referral'}
          onClick={() => setIsMenuOpen(false)}
        >
          {t('promoCode')}
        </NavLink>
      ),
    },
    {
      key: '6',
      label: t('logout'),
      onClick: () => {
        onLogout();
      },
      danger: true,
    },
  ];

  return (
    <>
      {isAuth && !isLoadingProfileData && profileData && (
        <>
          {width > MEDIA.M1024 && (
            <div className="header-profile">
              <Space.Compact>
                <Dropdown
                  menu={{ items }}
                  trigger={['click']}
                  align={{ offset: [0, 0] }}
                  open={isMenuOpen}
                  overlayClassName={'header-profile__dropdown-menu'}
                  onOpenChange={status => setIsMenuOpen(status)}
                  // @ts-ignore
                  getPopupContainer={() =>
                    document.querySelector('.header-sticky')
                  }
                >
                  <Button className="header-profile__btn-menu">
                    <Avatar
                      className="header-profile__avatar"
                      size={EAvatarSizeType.Small}
                      src={profileData.avatar?.small}
                    >
                      {profileData.username.charAt(0)}
                    </Avatar>
                    <span className="header-profile__name">
                      {profileData.username}
                    </span>
                    <ArrowDownIcon />
                  </Button>
                </Dropdown>

                <Popover
                  placement="bottomLeft"
                  content={
                    <HeaderNotices
                      onClose={setIsNotificationsOpen}
                      onReadComplete={onReadNoticeComplete}
                      notice={notifications}
                    />
                  }
                  trigger="click"
                  open={isNotificationsOpen}
                  onOpenChange={setIsNotificationsOpen}
                  // @ts-ignore
                  getPopupContainer={() =>
                    document.querySelector('.header-sticky')
                  }
                >
                  <Badge
                    count={notifications ? notifications.length : 0}
                    overflowCount={5}
                  >
                    <Button
                      icon={<NotificationIcon />}
                      className="header-profile__btn-notice"
                    />
                  </Badge>
                </Popover>
              </Space.Compact>
            </div>
          )}
        </>
      )}
      {!isAuth && (
        <div className="header-profile">
          <Button type={EButtonTypeType.Primary} onClick={() => onLogin()}>
            <SteamIcon />
            {t('login')}
          </Button>
        </div>
      )}

      <ModalTeleport
        centered
        modalId={'steamAuth'}
        width={500}
        modalClass={'steam-auth-modal__wrapper'}
      >
        <SteamAuthModal modalId={'steamAuth'} />
      </ModalTeleport>
    </>
  );
};

export { HeaderProfile };
