import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';

import { queryClient } from 'config/queryClient';
import { POST_LOGIN } from 'api/auth';
import { useAuthStore } from 'store';

import { TLoginRequestParams, ILoginResponse } from 'types/api';

interface UseLoginOptions
  extends Omit<
    UseMutationOptions<ILoginResponse, unknown, TLoginRequestParams>,
    'onSuccess' | 'onError'
  > {
  onSuccess?: (data: ILoginResponse) => void;
  onError?: (error: unknown) => void;
}

type UseLoginHook = (
  options?: UseLoginOptions,
) => UseMutationResult<ILoginResponse, unknown, TLoginRequestParams>;

export const useMutateLogin: UseLoginHook = options => {
  const { t } = useTranslation();
  const { setAccessToken } = useAuthStore();
  return useMutation(POST_LOGIN, {
    ...options,
    onSuccess: data => {
      if (data.accessToken) {
        const cookies = new Cookies(null, { path: '/' });

        cookies.set('access_token', data.accessToken, {
          domain: window.location.hostname,
          expires: new Date(data.expireAccessTokenEndDate),
        });

        let chromeExtensionId = cookies.get('extension_installed_id');
        if (chromeExtensionId && chrome?.runtime) {
          chrome.runtime.sendMessage(
            chromeExtensionId,
            {
              from: 'MARKET',
              type: 'login_market',
            },
            response => {
              if (chrome.runtime.lastError) {
                console.log('login_market error', chrome.runtime.lastError);
              }
            },
          );
        }

        localStorage.setItem('access_token', data.accessToken);
        localStorage.setItem(
          'expires_access_token',
          data.expireAccessTokenEndDate,
        );
        localStorage.setItem('refresh_token', data.refreshToken);
        setAccessToken(data.accessToken);
        toast.success(t('toastSuccessLogin'));
        queryClient.invalidateQueries(['fetchProfileInfo']);
      }
    },
    onError: (err: AxiosError) => {
      const message = err ? err?.message : 'Error while trying Login';
      console.error(message);
      return message;
    },
  });
};
