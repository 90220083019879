import React from 'react';

// utils
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// store
import { useAppStore } from 'store';

// types
import { EButtonSizeType, EButtonTypeType } from 'types/units';

// components
import { Button } from 'antd';

// styles
import './index.scss';

// data
/*@ts-ignore*/
import Maskot from 'assets/img/successRefillMaskot.png';
import { HeaderAlertTimer } from '../../Header/HeaderAlert/HeaderAlertTimer';

const RefillSuccess = ({ modalId }) => {
  const { t } = useTranslation();
  const { sModalClose } = useAppStore();
  const navigate = useNavigate();
  const onConfirm = id => {
    sModalClose(id);
    navigate('/market');
  };
  return (
    <div className="withdraw-balance-confirmation success-refill-modal">
      <div className="withdraw-balance-confirmation__head withdraw-balance-confirmation__head--small">
        <div className="withdraw-balance-confirmation__head--bg">
          <img src={Maskot} alt="Skinsty" />
        </div>
      </div>
      <div className="withdraw-balance-confirmation__desc">
        <div className="withdraw-balance-confirmation__desc--title">
          <span>{t('refillSuccess.title')}</span>
        </div>
        <div className="withdraw-balance-confirmation__desc--content">
          {t('refillSuccess.desc')}
        </div>
      </div>
      <div className="withdraw-balance-confirmation__actions withdraw-balance-confirmation__actions--light">
        <Button
          block
          type={EButtonTypeType.Primary}
          size={EButtonSizeType.Large}
          onClick={() => {
            onConfirm(modalId);
          }}
        >
          {t('refillSuccess.button')}
        </Button>
      </div>
    </div>
  );
};

export { RefillSuccess };
