import React, { useMemo } from 'react';
import { useWindowSize } from 'react-use';
import { Button, Input, Form } from 'antd';
import { MEDIA } from '../../../../../utils/constants';
import { formatFloat, formatPrice } from '../../../../../utils/commons';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
// core
// utils
// locales
// store
import { useAppStore } from 'store';
// types
import { IPropsType } from './types';
import { EInputSizeType, EButtonSizeType } from 'types/units';
import { EGameType } from 'types/models';
import { EFloatRangeSizeType } from '../../../../atoms/FloatRange/types';
// components(atoms)
import { StickersBox } from 'components/features';
import { FloatRange } from 'components/atoms';
import { LightningIcon } from 'components/icons';

// styles
import '../index.scss';

// data

// func

const SellModalCard = ({
  data,
  currentPrice,
  className,
  onPriceChange,
}: IPropsType) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const { gameType, commission_percentage } = useAppStore();

  const [form] = Form.useForm();

  const controlsSchema = yup.object().shape({
    buyerPrice: yup
      .number()
      .test(
        'maxDigitsAfterDecimal',
        t('validations.amount'),
        // @ts-ignore
        number => /^\d+(\.\d{1,2})?$/.test(number),
      )
      .required(t('validations.required'))
      .min(0.03, t('validations.minValue', { min: 0.03 }))
      .max(99999, t('validations.maxValue', { max: 99999 }))
      .typeError(t('validations.isNumeric')),
    yourPrice: yup
      .number()
      .test(
        'maxDigitsAfterDecimal',
        t('validations.amount'),
        // @ts-ignore
        number => /^\d+(\.\d{1,2})?$/.test(number),
      )
      .required(t('validations.required'))
      .min(0.03, t('validations.minValue', { min: 0.03 }))
      .max(99999, t('validations.maxValue', { max: 99999 }))
      .typeError(t('validations.isNumeric')),
  });

  const yupSync = {
    async validator({ field }, value) {
      await controlsSchema.validateSyncAt(field, { [field]: value });
    },
  };

  const Stickers = useMemo(() => {
    let collection =
      gameType === EGameType.CSGO
        ? [...data.stickers, ...data.keychains]
        : data.gems;
    if (!collection.length) return null;
    return (
      <StickersBox
        collection={collection}
        sku={data.sku}
        gameType={gameType}
        containerClassName={'trade-modal-card__stickers'}
        itemClassName={'trade-modal-card__sticker'}
      />
    );
  }, [data, gameType]);

  const Prices = useMemo(() => {
    let { minMarketPrice, maxAutoBuyPrice } = data;
    return (
      <div className="trade-modal-card__prices">
        <div className="trade-modal-card__price">
          {t('minPrice')}
          <span>
            {minMarketPrice ? `$${formatPrice(minMarketPrice)}` : '$0.01'}
          </span>
        </div>
        <div className="trade-modal-card__price">
          {t('autobuy')}
          <span>
            {maxAutoBuyPrice ? `$${formatPrice(maxAutoBuyPrice)}` : '---'}
          </span>
        </div>
      </div>
    );
  }, [data, t]);

  const onChangeBuyerPrice = ({ target: { value } }) => {
    let numberValue = Number(value) ? Number(value) : 0;
    let yourPrice = (numberValue * (1 - commission_percentage)).toFixed(2);
    form.setFieldValue('yourPrice', yourPrice);
    onPriceChange(numberValue);
  };

  const onChangeYourPrice = ({ target: { value } }) => {
    let numberValue = Number(value) ? Number(value) : 0;
    let buyerPrice = (numberValue / (1 - commission_percentage)).toFixed(2);
    form.setFieldValue('buyerPrice', buyerPrice);
    onPriceChange(Number(buyerPrice));
  };

  const onSetAutoBuyPrice = () => {
    let marketPrice = data.maxAutoBuyPrice;

    form.setFieldValue('buyerPrice', marketPrice.toFixed(2));

    let yourPrice = (marketPrice * (1 - commission_percentage)).toFixed(2);
    form.setFieldValue('yourPrice', yourPrice);

    onPriceChange(Number(marketPrice));
  };

  return (
    <div
      className={
        'trade-modal-card trade-modal-card--extended' +
        (className ? ` ${className}` : '')
      }
    >
      <div className="trade-modal-card__left">
        <div className="trade-modal-card__img">
          <img
            src={data.preview?.length ? data.preview : data.image}
            alt={data.name}
          />
        </div>
        <div className="trade-modal-card__info">
          <div className="trade-modal-card__title foo">
            {data.marketName.first}
          </div>
          <div className="trade-modal-card__label">
            {data.marketName.second}
          </div>
          {gameType === EGameType.CSGO ? (
            <>
              {data.float > 0 && (
                <div className="trade-modal-card__float">
                  <div className="trade-modal-card__float-grid">
                    {data.shortenExterior}
                    <span>{formatFloat(data.float)}</span>
                  </div>
                  <FloatRange
                    isTooltip={!!data.float}
                    size={EFloatRangeSizeType.Small}
                    maxValue={data.float ? formatFloat(data.float) : 0}
                  />
                </div>
              )}
              {Stickers}
            </>
          ) : (
            <>
              <div className="trade-modal-card__label">{data.slot}</div>
              {Stickers}
            </>
          )}
          {width > MEDIA.M768 && width <= MEDIA.M1280 && Prices}
        </div>
      </div>
      <div className="trade-modal-card__right">
        {(width <= MEDIA.M768 || width > MEDIA.M1280) && Prices}
        <div className="trade-modal-card__divider" />
        <Form
          form={form}
          layout="vertical"
          className="trade-modal-card__form"
          initialValues={{
            buyerPrice: formatPrice(currentPrice, 2, false),
            yourPrice: formatPrice(
              currentPrice - currentPrice * commission_percentage,
              2,
              false,
            ),
          }}
        >
          <div className="trade-modal-card__input">
            <label>{t('buyerPays')}</label>
            <Form.Item
              className="ant-form-item--flat"
              name={'buyerPrice'}
              /*@ts-ignore*/
              rules={[yupSync]}
            >
              <Input
                size={EInputSizeType.Middle}
                prefix={'$'}
                onChange={onChangeBuyerPrice}
              />
            </Form.Item>

            <div className="trade-modal-card__input-note">
              {t('minPrice')}: $0.03
            </div>
          </div>
          <div className="trade-modal-card__input">
            <label>{t('youGet')}</label>
            <Form.Item
              className="ant-form-item--flat"
              name={'yourPrice'}
              /*@ts-ignore*/
              rules={[yupSync]}
            >
              <Input
                size={EInputSizeType.Middle}
                prefix={'$'}
                onChange={onChangeYourPrice}
              />
            </Form.Item>

            <div className="trade-modal-card__input-note">
              {t('commission')}: $
              {(currentPrice * commission_percentage).toFixed(2)}
            </div>
          </div>
          <div className="trade-modal-card__additional">
            <Button
              disabled={!data.maxAutoBuyPrice}
              size={EButtonSizeType.Middle}
              className="ant-btn-default--light trade-modal-card__btn-auto"
              onClick={() => onSetAutoBuyPrice()}
            >
              <LightningIcon /> ${formatPrice(data.maxAutoBuyPrice)}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export { SellModalCard };
