export enum ERefillResponseType {
  HTML = 'html',
  URL = 'url',
  JSON = 'json',
  Form = 'form',
}
export enum ERefillModalType {
  Success = 'success',
  Error = 'fail',
}
export enum ERefillTaxType {
  Percentage = 'percentage',
  Fixed = 'fixed',
}
