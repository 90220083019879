import React, { useState, useEffect, useMemo } from 'react';
import { Button, Table, Typography } from 'antd';
import { MEDIA } from 'utils/constants';
import { useWindowSize } from 'react-use';
import { useNavigate, useLocation } from 'react-router-dom';
import { formatDate, formatPrice } from '../../../../utils/commons';
// locale
import { useTranslation } from 'react-i18next';
//hooks
import { useQueryFetchWalletHistory } from '../../../../hooks/api';
// store
// types
import { EButtonSizeType } from 'types/units';
import { ECardSizeType } from 'components/features/Cards/EmptyCard/types';
import { EPaymentSystemType } from 'types/models';
// components

import {
  EmptyCard,
  MobileBottomNav,
  TableCellStatus,
} from 'components/features';
import {
  MasterCardIcon,
  VisaCardIcon,
  PayPalIcon,
  BitcoinIcon,
  EtheriumIcon,
  CopyIcon,
} from 'components/icons';
import { ArrowLeftLightIcon, ArrowRightIcon } from 'components/icons';

// styles
import './index.scss';
import { HistoryHead } from '../HistoryHead';

//data
const HistoryWithdrawal = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { width } = useWindowSize();
  const navigate = useNavigate();

  const [historyFilter, setHistoryFilter] = useState({
    page: 1,
    take: 10,
    type: pathname.replace(/^\/history\//, ''),
  });
  const { data: historyData, isLoading: isLoadingHistoryData } =
    useQueryFetchWalletHistory(historyFilter);

  const [tableData, setTableData] = useState<any>();

  useEffect(() => {
    if (!isLoadingHistoryData && historyData) {
      setTableData(transformData(historyData.data));
    }
  }, [isLoadingHistoryData, historyData]);

  const transformData = list => {
    return list.map((el, index) => {
      let {
        tID,
        resolvedAt,
        status,
        paymentDirection,
        paymentServiceIcon,
        userRequisites,
        total,
        amount,
      } = el;

      return {
        id: index,
        paymentId: tID,
        date: formatDate(resolvedAt, 'DD.MM.YYYY HH:mm'),
        status: status,
        price: total,
        amount: amount,
        paymentService: paymentDirection,
        paymentServiceIcon: paymentServiceIcon,
        userRequisites: userRequisites,
      };
    });
  };

  const onTableChange = pagination => {
    setHistoryFilter({ ...historyFilter, page: pagination.current });
  };

  const dynamicColumns = useMemo(() => {
    return [
      {
        key: 'date',
        dataIndex: 'date',
        render: date => <div className="ant-table-cell__date">{date}</div>,
      },
      {
        key: 'paymentId',
        dataIndex: 'paymentId',
        width: '35%',
        render: (_, record) => (
          <div className="ant-table-cell__id ant-table-cell__id--sx">
            ID:{' '}
            <Typography.Paragraph
              className="table-cell-name__type"
              copyable={{
                icon: [
                  <CopyIcon key="copy-icon" />,
                  <CopyIcon key="copied-icon" />,
                ],
                text: record.paymentId,
              }}
            >
              <span className="ant-table-cell__value">{record.paymentId}</span>
            </Typography.Paragraph>
          </div>
        ),
      },
      {
        key: 'paymentSystem',
        dataIndex: 'paymentSystem',
        width: '25%',
        render: (_, record) => (
          <div className="ant-table-cell__nowrap ant-table-cell__nowrap--centered">
            {t('historyPaymentSystem')}:{' '}
            <div className="ant-table-cell__payment">
              {record.paymentServiceIcon?.length ? (
                <div className="ant-table-cell__payment-icon">
                  <img
                    src={record.paymentServiceIcon}
                    alt={record.paymentService}
                  />
                </div>
              ) : (
                <div className="history-withdrawal__unknown-card" />
              )}
              <div className="ant-table-cell__payment-title">
                {record.paymentService}
              </div>
            </div>
          </div>
        ),
      },
      {
        key: 'userRequisites',
        dataIndex: 'userRequisites',
        render: userRequisites =>
          userRequisites?.length ? (
            <div className="ant-table-cell__nowrap ant-table-cell__nowrap--centered">
              {t('userRequisites')}{' '}
              <div className="ant-table-cell__value">{userRequisites}</div>
            </div>
          ) : (
            <></>
          ),
      },
      {
        key: 'price',
        dataIndex: 'price',
        width: '20%',
        render: price => (
          <div className="ant-table-cell__nowrap ant-table-cell__nowrap--centered">
            {t('withdrawHistorySumm')}{' '}
            <div className="ant-table-cell__value"> $ {formatPrice(price)}</div>
          </div>
        ),
      },
      {
        key: 'amount',
        dataIndex: 'amount',
        width: '20%',
        render: amount => (
          <div className="ant-table-cell__nowrap ant-table-cell__nowrap--centered">
            {t('withdrawHistorySummTax')}{' '}
            <div className="ant-table-cell__value">
              {' '}
              $ {formatPrice(amount)}
            </div>
          </div>
        ),
      },
      // {
      //   key: 'cardNumber',
      //   render: (_, record) => (
      //     <div className="ant-table-cell__nowrap ant-table-cell__value">
      //       {record.cardFirst4Numbers} **** **** {record.cardLast4Numbers}
      //     </div>
      //   ),
      // },
      {
        key: 'status',
        dataIndex: 'status',
        render: status => <TableCellStatus status={status} />,
      },
    ];
  }, [t]);

  return (
    <>
      <HistoryHead filterEnabled={false} />
      <div className="history-withdrawal">
        <div className="app-container">
          <Table
            showHeader={false}
            onChange={pagination => {
              onTableChange(pagination);
            }}
            loading={isLoadingHistoryData}
            pagination={
              isLoadingHistoryData
                ? false
                : {
                    position: ['bottomCenter'],
                    current: historyData?.meta?.page,
                    pageSize: historyData?.meta?.take,
                    total: historyData?.meta?.itemsCount,
                    showSizeChanger: false,
                    hideOnSinglePage: true,
                    itemRender: (page, type, originalElement) => {
                      if (type === 'next') {
                        return <ArrowRightIcon />;
                      } else if (type === 'prev') {
                        return <ArrowLeftLightIcon />;
                      }
                      return originalElement;
                    },
                  }
            }
            className="history-withdrawal__table"
            columns={dynamicColumns}
            dataSource={tableData}
            rowKey="id"
            scroll={{ x: '980px' }}
            locale={{
              emptyText: (
                <div className="ant-table-cell__table-empty">
                  <EmptyCard
                    size={ECardSizeType.Small}
                    title={t('emptyHistoryTitle')}
                    description={t('emptyHistoryDesc')}
                  />
                  <MobileBottomNav
                    breakPoint={768}
                    className="history-withdrawal__empty-actions"
                  >
                    <Button
                      className="history-withdrawal__empty-btn-market"
                      type="primary"
                      block={width <= MEDIA.M768}
                      size={EButtonSizeType.Large}
                      onClick={() => {
                        navigate('/market');
                      }}
                    >
                      {t('goToMarket')}
                    </Button>
                  </MobileBottomNav>
                </div>
              ),
            }}
          />
        </div>
      </div>
    </>
  );
};

export { HistoryWithdrawal };
