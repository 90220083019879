import { $api } from '../utils/request';
import {
  TWithdrawCreateRequestPayload,
  TWithdrawCreateResponse,
  TWithdrawMethodsResponse,
} from 'types/api';

export const GET_WITHDRAW_METHODS = async code => {
  const url = `wallet/withdraw/form?country=${code}`;
  const response = await $api.get<any>(url);
  return response.data;
};
export const GET_REFILL_COUNTRIES = async () => {
  const url = `wallet/refill/list`;
  const response = await $api.get<any>(url);
  return response.data;
};
export const GET_WITHDRAW_COUNTRIES = async () => {
  const url = `wallet/withdraw/list`;
  const response = await $api.get<any>(url);
  return response.data;
};
export const GET_REFILL_METHODS = async code => {
  const url = `wallet/refill/form?country=${code}`;
  const response = await $api.get<any>(url);
  return response.data;
};
export const SEND_REFILL = async (payload: any) => {
  const url = `wallet/refill/new?amount=${payload.amount}&country=${payload.code}&currency=${payload.currency}`;
  const response = await $api.post<any>(url, payload.payload);
  return response.data;
};

export const CREATE_WITHDRAW_REQUEST = async (payload: any) => {
  const url = `wallet/withdraw/new?country=${payload.country}`;
  const response = await $api.post<TWithdrawCreateResponse>(url, payload.data);
  return response.data;
};
